const state = {
    variablesFormVuex: [
        { "id": 1, "name": "Temperature", "icons": ["mdi-thermometer"] },
        { "id": 2, "name": "Pressure", "icons": ["mdi-gauge"] },
        { "id": 3, "name": "Relative Humidity", "icons": ["mdi-water-percent"] },
        { "id": 4, "name": "Level", "icons": ["mdi-beaker"] },
        { "id": 5, "name": "Voltage", "icons": ["mdi-car-battery"] },
        { "id": 6, "name": "Electric current", "icons": ["mdi-current-dc"] },
        {
            "id": 7,
            "name": "Rotational Speed",
            "icons": ["mdi-axis-z-rotate-clockwise"]
        },
        { "id": 8, "name": "State", "icons": ["mdi-toggle-switch"] },
        { "id": 9, "name": "Speed", "icons": ["mdi-speedometer"] },
        { "id": 10, "name": "Length", "icons": ["mdi-ruler"] },
        { "id": 11, "name": "Signal", "icons": ["mdi-signal"] },
        { "id": 12, "name": "Heart Rate", "icons": ["mdi-heart"] },
        {
            "id": 13,
            "name": "Heart Rate Variability",
            "icons": ["mdi-heart-multiple"]
        },
        {
            "id": 14,
            "name": "Blood Pressure",
            "icons": ["mdi-heart-multiple", "mdi-heart-plus", "mdi-heart-minus"]
        },
        {
            "id": 15,
            "name": "Blood Oxygen Saturation",
            "icons": ["mdi-gas-cylinder"]
        },
        { "id": 16, "name": "Respiration Rate", "icons": ["mdi-lungs"] },
        { "id": 17, "name": "Time", "icons": ["mdi-clock-time-three-outline"] },
        {
            "id": 18,
            "name": "Energy Consumption",
            "icons": ["mdi-home-lightbulb-outline"]
        },
        { "id": 19, "name": "Power", "icons": ["mdi-lightning-bolt"] },
        { "id": 20, "name": "Emission", "icons": ["mdi-molecule-co2"] },
        {
            "id": 21,
            "name": "Money",
            "icons": ["mdi-currency-usd", "mdi-cash-multiple"]
        },
        { "id": 22, "name": "pH", "icons": ["mdi-ph"] },
        {
            "id": 23,
            "name": "Electrical Conductivity",
            "icons": ["mdi-lightning-bolt-circle"]
        },
        { "id": 24, "name": "Dissolved oxygen", "icons": ["mdi-gas-cylinder"] },
        { "id": 25, "name": "On/Off", "icons": ["mdi-orbit-variant"] },
        { "id": 26, "name": "Number", "icons": ["mdi-numeric"] },
        { "id": 27, "name": "String", "icons": ["mdi-alphabetical"] }
    ]
}
const mutations = {}
const getters = {}
const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}