import Zone from '@/services/zone.api';

const state = {
};

const mutations = {};

const getters = {};

const actions = {
    getZones: (_store, options) =>
        Zone.getZones(options)
            .then(({ data }) => data)
            .catch((err => console.error(err))),
    getZonesTable: (_store, { page, filter }) =>
        Zone.getZonesTable(page, filter)
            .then(({ data }) => data)
            .catch((err => console.error(err))),
    createZone: (_store, zone) =>
        Zone.createZone(zone)
            .then(({ data }) => data)
            .catch((err => console.error(err))),
    editZone: (_store, zone) =>
        Zone.editZone(zone)
            .then(({ data }) => data)
            .catch((err => console.error(err))),
    deleteZone: (_store, zone) =>
        Zone.deleteZone(zone)
            .then(({ data }) => data).catch((err) => console.error(err)),
    getZone: (_store, zone) =>
        Zone.getZone(zone)
            .then(({ data }) => data)
            .catch((err => console.error(err)))

};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};