import Contact from '../../services/contact.api';

const state = {};

const mutations = {};

const getters = {};

const actions = {
  getContacts: (_store, { page, filter }) =>
    Contact.getContacts(page, filter)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
