import httpClient from './httpClient';

const baseEndPoint = '/timezones';

export const getTimezones = () =>
    httpClient.get(baseEndPoint);

export default {
    getTimezones,
};
