import httpClient from './httpClient';

const baseEndPoint = '/vehicle';

const getVehicle = (id) =>
    httpClient.get(`${baseEndPoint}/${id}`)

const getvehicles = (page, filter) =>
    httpClient.get(`${baseEndPoint}s`, { params: { page: page, filter: filter } })

function getFakeVehicles(){
    var trucks = [
        {
            type: "Truck",
            driver:"Diego Hinojosa",
            plate: "ATUJ20",
            permission: "PASSED"
        },
        {
            type: "Truck",
            driver:"Bob Contructor",
            plate: "AHPB27",
            permission: "FAIL"
        }
    ]
    return new Promise(resolve => {
        setTimeout(() => {
          resolve(trucks);
        }, 200);
      });
}



export default {
    getVehicle,
    getvehicles,
    getFakeVehicles
};