var render = function render(){var _vm=this,_c=_vm._self._c;return _c('md-toolbar',{staticClass:"md-transparent",attrs:{"md-elevation":"0"}},[_c('div',{staticClass:"md-toolbar-row"},[_c('div',{staticClass:"md-toolbar-section-start"},[_c('h3',{staticClass:"md-title"},[_vm._v(_vm._s(_vm.$t(`title.${_vm.$route.meta.translate}`)))])]),_c('div',{staticClass:"md-toolbar-section-end"},[_c('md-button',{staticClass:"md-just-icon md-simple md-toolbar-toggle",class:{ toggled: _vm.$sidebar.showSidebar },on:{"click":_vm.toggleSidebar}},[_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"})]),_c('div',{staticClass:"md-collapse"},[_c('md-list',[_c('md-list-item',[_c('div',{staticClass:"row justify-center align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":this.$tgcWebsocket.isConnected() ? 'green' : 'red',"size":"15"}},[_vm._v("mdi-circle")]),_c('span',{staticClass:"mb-1"},[_vm._v("ws: "+_vm._s(this.$tgcWebsocket.isConnected() ? _vm.$t("websocket.connected") : _vm.$t("websocket.disconnected")))])],1)]),_c('li',{staticClass:"md-list-item"},[_c('div',{staticClass:"md-list-item-content"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('md-button',_vm._g(_vm._b({staticClass:"md-button md-just-icon md-simple",attrs:{"slot":"title","color":"primary","data-toggle":"dropdown"},slot:"title"},'md-button',attrs,false),on),[_c('md-icon',[_vm._v("notifications")]),(
                        _vm.$store.getters[
                          'notification/unreadNotificationsQuantity'
                        ] > 0
                      )?_c('span',{staticClass:"notification"},[_vm._v(_vm._s(_vm.$store.getters[ "notification/unreadNotificationsQuantity" ]))]):_vm._e(),_c('p',{staticClass:"hidden-lg hidden-md"},[_vm._v("Notifications")])],1)]}}])},[_c('v-list',[_vm._l((_vm.$store.getters[
                      'notification/notifications'
                    ]),function(notification){return _c('v-list-item',{key:notification.id},[_c('v-list-item-title',[_c('router-link',{attrs:{"to":'/notification/' + notification.id + '/details'}},[_c('span',{class:{ bold: !notification.seen }},[_vm._v(_vm._s(notification.message.length > 20 ? notification.message.slice(0, 20) + "..." : notification.message))])])],1)],1)}),_c('v-list-item',[_c('router-link',{staticClass:"w-100 text-center",attrs:{"to":"/notifications"}},[_vm._v("See all")])],1)],2)],1)],1)]),_c('md-list-item',[_c('router-link',{attrs:{"to":{ name: 'myInfo' }}},[_c('i',{staticClass:"material-icons"},[_vm._v("person")]),_c('p',{staticClass:"hidden-lg hidden-md"},[_vm._v("Profile")])])],1),_c('md-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("power_settings_new")]),_c('p',{staticClass:"hidden-lg hidden-md"},[_vm._v("power_settings_new")])]),_c('form',{ref:"form",staticStyle:{"display":"none"},attrs:{"id":"logout-form","action":"/logout","method":"POST"}})],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }