import Calibrator from '../../services/calibrator.api';

const state = {};

const mutations = {};

const getters = {};

const actions = {
    getFunctionTypes: () =>
        Calibrator.getFunctionTypes()
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    getItem: (_store, { device, slot }) =>
        Calibrator.getItem(device, slot)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    //In future change (_store, {object}) for (_store, {item}) and add format function on view
    sendItem: (_store, { device, slot, calibrator }) =>
        Calibrator.sendItem(device, slot, calibrator)
            .then(({ data }) => data)
            .catch((err => console.error(err)))

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};