import workerForm from './workerForm';
import workerTab from './workerTab'
import workerDetail from './workerDetail';

const workerEs = {
    workerTableTitle: 'Trabajadores',
    workerForm: workerForm.workerFormEs,
    workerTab: workerTab.workerTabEs,
    workerDetail: workerDetail.workerDetailEs
}

export default {
    workerEs
}