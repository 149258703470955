const dashboardViewEs = {
    users: 'Usuarios',
    workers: 'Trabajadores',
    devices: 'Dispositivos',
    notifications: 'Notificaciones',
    inOrganization: 'En la organización',
    haventSee: 'Que aun no has visto',
    mapTitle: 'Dispositivos'
}

export default {
    dashboardViewEs
}