import httpClient from "./httpClient";

const baseEndPoint = "/v1/device";

const getDowntimeEvents = (identifier, options) =>
  httpClient.get(`${baseEndPoint}/${identifier}/downtime-events`, {
    params: options,
  });

const getAllEvents = (identifier, options) =>
  httpClient.get(`${baseEndPoint}/${identifier}/all-events`, {
    params: options,
  });

const getEvents = (identifier, options) =>
  httpClient.get(`${baseEndPoint}/${identifier}/events`, { params: options });

const getSlotEvents = (identifier, options) =>
  httpClient.get(`${baseEndPoint}/${identifier}/slot/events`, {
    params: options,
  });

const getEvent = (eventId) =>
  httpClient.get(`${baseEndPoint}/event/${eventId}`);

const getLatestTrip = (identifier) =>
  httpClient.get(`${baseEndPoint}/${identifier}/lastest-trip`);

export default {
  getDowntimeEvents,
  getAllEvents,
  getEvents,
  getSlotEvents,
  getEvent,
  getLatestTrip,
};
