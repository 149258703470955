import httpClient from './httpClient';

const baseEndPoint = '/v1/file-manager';

const uploadProfilePhoto = (profilePhoto) => httpClient.post(`${baseEndPoint}/profile_photos`, profilePhoto)


export default {
    uploadProfilePhoto
};
