const slotListenerFormEs = {
  titleFormCreate: "Crear nuevo observador",
  name: "Nombre",
  listenerType: "Tipo de observador",
  conditionType: "Tipo de condición",
  calibratedValue: "Valor calibrado",
  value: "Valor",
};

export default {
  slotListenerFormEs,
};
