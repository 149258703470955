import Displayers from '../../services/displayers.api';

const state = {};

const mutations = {};

const getters = {};

const actions = {
    getDeviceDisplayers: (_store, device) =>
        Displayers.getDeviceDisplayers(device)
            .then(({ data }) => data)
            .catch((err => console.error(err))),

    getOrganizationDisplayers: (_store) =>
        Displayers.getOrganizationDisplayers()
            .then(({ data }) => data)
            .catch((err => console.error(err)))
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};