// import the api endpoints
import User from '../../services/user.api';

const state = {
  user: {
    id: 0,
    name: '',
    email: '',
    tz: '',
    organization: '',
    needsToChangePassword: false,
  }
};

const mutations = {
  USER(state, user) {
    state.user = user;
  }
};

const getters = {
  user(state) {
    return state.user;
  }
};

const actions = {
  getUser: (_store, user) =>
    User.getUser(user)
      .then(({ data }) => data)
  ,
  getUserInfo: ({ commit }) =>
    User.getUserInfo()
      .then(({ data }) => {

        commit('USER', data);

        return data;
      })
  ,
  getUsers: (_store, { page, filter }) =>
    User.getUsers(page, filter)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  getOrganizationUsers: () =>
    User.getOrganizationUsers()
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  storeUser: (_store, user) =>
    User.storeUser(user)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  updateUser: (_store, user) =>
    User.updateUser(user)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  deleteUser: (_store, user) =>
    User.deleteUser(user)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  editUserInfo: (_store, user) =>
    User.editUserInfo(user)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
