import Vue from "vue";
import Vuex from "vuex";
import account from "./modules/account.store";
import listener from "./modules/listener.store";
import contact from "./modules/contact.store";
import Permission from "../services/permission.api";
import notification from "./modules/notification.store";
import timezone from "./modules/timezone.store";
import organization from "./modules/organization.store";
import device from "./modules/device.store";
import user from "./modules/user.store";
import userV1 from "./modules/userV1.store";
import role from "./modules/role.store";
import annotations from "./modules/annotations.store";
import notificationChannel from "./modules/notificationChannel.store";
import summary from "./modules/summary.store";
import department from "./modules/department.store";
import technologyType from "./modules/technologyType.store";
import deviceType from "./modules/deviceType.store";
import proximityEvent from "./modules/proximityEvent.store";
import monitoring from "./modules/monitoring.store";
import slot from "./modules/slot.store";
import worker from "./modules/worker.store";
import vehicle from "./modules/vehicle.store";
import calibrator from "./modules/calibrator.store";
import icon from "./modules/icon.store";
import login from "./modules/login.store";
import displayModes from "./modules/displayModes.store";
import displayers from "./modules/displayers.store";
import dashboard from "./modules/dashboard.store";
import template from "./modules/template.store";
import zone from "./modules/zone.store";
import downtimeEvents from "./modules/downtimeEvent.store";
import events from "./modules/events.store";
import dynamicForm from "./modules/dynamicForm.store";
import fileManager from "./modules/fileManager.store";
import screen from "./screen";
import variables from "./variables";
import unitOfMeasurement from "./unitOfMeasurement";

import auth from "./auth";
import mobile from "./mobile";
import websocket from "./websocket";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
    listener,
    contact,
    device,
    notification,
    user,
    role,
    annotations,
    notificationChannel,
    summary,
    department,
    technologyType,
    deviceType,
    proximityEvent,
    monitoring,
    slot,
    worker,
    vehicle,
    calibrator,
    auth,
    icon,
    displayModes,
    displayers,
    dashboard,
    template,
    downtimeEvents,
    variables,
    unitOfMeasurement,
    zone,
    timezone,
    organization,
    userV1,
    login,
    screen,
    mobile,
    events,
    websocket,
    dynamicForm,
    fileManager,
  },
  state: {
    permissions: [],
    packageJson: JSON.parse(unescape(process.env.PACKAGE_JSON || "%7B%7D")),
    theme: "mainTheme",
  },
  mutations: {
    PERMISSIONS(state, permissions) {
      state.permissions = permissions;
      const storagePermissions = JSON.stringify(permissions);
      localStorage.setItem("userPermissions", storagePermissions);
    },
  },
  actions: {
    getUserPermissions({ commit }) {
      Permission.getUserPermissions().then(({ data }) => {
        const userPermissions = localStorage.getItem("userPermissions");
        const statePermissions = JSON.parse(userPermissions);
        if (data !== statePermissions) commit("PERMISSIONS", data);
        return data;
      });
    },
    getLocalPermissions: ({ commit }) => {
      const userPermissions = localStorage.getItem("userPermissions");
      if (userPermissions) {
        const statePermissions = JSON.parse(userPermissions);
        commit("PERMISSIONS", statePermissions);
      }
      return userPermissions;
    },
  },
  getters: {
    getPermissions(state) {
      return state.permissions;
    },
    getPermission(state) {
      return (permission) =>
        state.permissions.some((item) => {
          return item.name === permission;
        });
    },
    getLoaded(state) {
      return state.permissions.length > 0;
    },
    getTheme: (state) => state.theme,
  },
});
