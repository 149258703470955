import Department from '../../services/department.api';

const state = {};

const mutations = {};

const getters = {};

const actions = {
    getDepartments: (_store, options) =>
        Department.getDepartments(options)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    getDepartmentsTable: (_store, { page, filter }) =>
        Department.getDepartmentsTable(page, filter)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    deleteDepartment: (_store, department) =>
        Department.deleteDepartament(department)
            .then(({ data }) => data)
            .catch((err => console.error(err)))

    ,
    getDepartment: (_store, id) =>
        Department.getDepartment(id)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    createDepartment: (_store, { name, description }) =>
        Department.createDepartment(name, description)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    editDepartment: (_store, { id, name, description }) =>
        Department.editDepartment(id, name, description)
            .then(({ data }) => data)
            .catch((err => console.error(err)))

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};