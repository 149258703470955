import httpClient from './httpClient';

const baseEndPoint = '/get-device-types';

const getDeviceTypes = () =>
    httpClient.get(`${baseEndPoint}`)


export default {
    getDeviceTypes
};
