import slotForm from "./slotForm";
import slotTab from "./slotTab";
import slotDetail from "./slotDetail";
import slotCalibrator from "./slotCalibrator";
import slotListenerForm from "./slotListenerForm";

const slotEs = {
  slotForm: slotForm.slotFormEs,
  slotTab: slotTab.slotTabEs,
  slotDetail: slotDetail.slotDetailEs,
  slotCalibrator: slotCalibrator.slotCalibratorEs,
  slotListenerForm: slotListenerForm.slotListenerFormEs,
};

export default {
  slotEs,
};
