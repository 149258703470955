import httpClient from './httpClient';

const baseEndPoint = '/v1/dashboard';

const getDashboards = (options) =>
    httpClient.get(`${baseEndPoint}s`, { params: options })
const getDashboardsTable = (page, filter) =>
    httpClient.get(`${baseEndPoint}s-table`, { params: { page: page, filter: filter } })

const getDashboard = (dashboard) =>
    httpClient.get(`${baseEndPoint}/${dashboard}`)

const editDashboard = (dashboard) =>
    httpClient.put(`${baseEndPoint}`, dashboard)

const editDashboardContent = (dashboard) =>
    httpClient.put("v1/dashboard-quick-edit", dashboard)

const createDashboard = (dashboard) =>
    httpClient.post(`${baseEndPoint}`, dashboard)

const deleteDashboard = (dashboard) =>
    httpClient.delete(`${baseEndPoint}/${dashboard}`)

export default {
    getDashboards,
    getDashboardsTable,
    getDashboard,
    editDashboard,
    createDashboard,
    editDashboardContent,
    deleteDashboard
};
