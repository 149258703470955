const hintsEn = {
    symbol: 'Symbol',
    icon: 'Material Desing icons'
}

const hintsEs = {
    symbol: 'Simbolo',
    icon: 'Iconos de Material Desing'
}

export default {
    hintsEn,
    hintsEs
}