// import the api endpoints
import User from '../../services/userV1.api';

const state = {

};

const mutations = {

};

const getters = {

};

const actions = {
    getUsers: (_store, options) =>
        User.getUsers(options)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    getMyInfo: (_store) =>
        User.getMyInfo()
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    editMyInfo: (_store, info) =>
        User.editMyInfo(info)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    getUser: (_store, user) =>
        User.getUser(user)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    editUser: (_store, user) =>
        User.editUser(user)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    changePhone: (_store, phone) =>
        User.changePhone(phone)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    changeEmail: (_store, email) =>
        User.changeEmail(email)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    generateConfirmationCode: (_store, options) =>
        User.generateConfirmationCode(options)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    wizardStart: (_store, user) =>
        User.wizardStart(user)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    wizardStep1: (_store, user) =>
        User.wizardStep1(user)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    wizardStep2: (_store, user) =>
        User.wizardStep2(user)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    wizardStep3: (_store, user) =>
        User.wizardStep3(user)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    wizardStep4: (_store, user) =>
        User.wizardStep4(user)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    deactivateUser: (_store, user) =>
        User.deactivateUser(user)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    activateUser: (_store, user) =>
        User.activateUser(user)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
