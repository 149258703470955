import httpClient from './httpClient';

const baseEndPoint = '/v1/device';

const getDeviceDisplayers = (device) =>
    httpClient.get(`${baseEndPoint}/${device}/displayers`)

const getSlotDisplayers = (device, slot) =>
    httpClient.get(`${baseEndPoint}/${device}/slot/${slot}/displayers`)

const getOrganizationDisplayers = () =>
    httpClient.get('/v1/organization-displayers')

export default {
    getDeviceDisplayers,
    getSlotDisplayers,
    getOrganizationDisplayers
};
