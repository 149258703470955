import httpClient from './httpClient';

const baseEndPoint = '/v1/device-template';

const createTemplate = (device, template) => httpClient.post(`/v1/device/${device}/create-template`, template)
const createDeviceFromTemplate = (deviceTemplate, device) => httpClient.post(`${baseEndPoint}/${deviceTemplate}/create-device`, device)
const getTemplates = (options) => httpClient.get(`${baseEndPoint}s`, { params: options })
const getTemplate = (template) => httpClient.get(`${baseEndPoint}/${template}`)
const deleteTemplate = (template) => httpClient.delete(`${baseEndPoint}/${template}`)


export default {
    createTemplate,
    createDeviceFromTemplate,
    getTemplate,
    getTemplates,
    deleteTemplate
};
