var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(_vm.$route.meta.type === 'mobile' && _vm.enableHide)?_c('v-icon',{staticClass:"chevron-toggle",class:_vm.isVisible && _vm.enableHide ? 'chevron-toggle-down' : 'chevron-toggle-up',attrs:{"size":"25"},on:{"click":function($event){_vm.isVisible = !_vm.isVisible}}},[_vm._v(_vm._s(_vm.isVisible ? "mdi-chevron-down" : "mdi-chevron-up"))]):_vm._e(),(_vm.$route.meta.type === 'mobile')?_c('v-app-bar',{staticClass:"app-bar-mobile",attrs:{"bottom":"","fixed":"","color":"white","dark":"","height":"96","shrink-on-scroll":!_vm.enableHide && _vm.enableShrink,"hide-on-scroll":_vm.enableHide,"scroll-target":"#scrolling-techniques-4"},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('div',{staticClass:"row h-100 w-100 mt-3"},[_c('div',{staticClass:"col-3 d-flex flex-column align-center justify-center"},[_c('router-link',{attrs:{"to":{ name: 'mobileMap' }}},[_c('div',{staticClass:"col d-flex flex-column align-center justify-center"},[_c('v-icon',{staticClass:"app-bar-icon",attrs:{"color":_vm.$route.path.includes('/m/map') ? 'primary' : ' grey lighten-1'}},[_vm._v("mdi-map-search-outline")]),_c('span',{staticClass:"app-bar-icon-text mt-3",style:(_vm.$route.path.includes('/m/map')
                  ? 'color: #23c577!important'
                  : 'color: #BDBDBD!important')},[_vm._v("Mapa")])],1)])],1),_c('div',{staticClass:"col-3 d-flex flex-column align-center justify-center"},[_c('router-link',{attrs:{"to":{ name: 'mobileDevice' }}},[_c('div',{staticClass:"col d-flex flex-column align-center justify-center"},[_c('v-icon',{staticClass:"app-bar-icon",attrs:{"color":_vm.$route.path.includes('/m/devices')
                  ? 'primary'
                  : ' grey lighten-1'}},[_vm._v("mdi-sitemap")]),_c('span',{staticClass:"app-bar-icon-text mt-3",style:(_vm.$route.path.includes('/m/devices')
                  ? 'color: #23c577!important'
                  : 'color: #BDBDBD!important')},[_vm._v("Dispositivos")])],1)])],1),_c('div',{staticClass:"col-3 d-flex flex-column align-center justify-center"},[_c('router-link',{attrs:{"to":{ name: 'MobileNotificationTable' }}},[_c('div',{staticClass:"col d-flex flex-column align-center justify-center"},[_c('v-icon',{staticClass:"app-bar-icon",attrs:{"color":_vm.$route.path.includes('/m/notifications')
                  ? 'primary'
                  : ' grey lighten-1'}},[_vm._v("mdi-bell-outline")]),_c('span',{staticClass:"app-bar-icon-text mt-3",style:(_vm.$route.path.includes('/m/notifications')
                  ? 'color: #23c577!important'
                  : 'color: #BDBDBD!important')},[_vm._v("Alertas")])],1)])],1),_c('div',{staticClass:"col-3 d-flex flex-column align-center justify-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"col d-flex flex-column align-center justify-center"},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey lighten-1","data-v-step":"3"}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")]),_c('span',{staticClass:"app-bar-icon-text mt-3",staticStyle:{"color":"#bdbdbd !important"}},[_vm._v("Más")])],1)]}}],null,false,3593660925)},[_c('v-list',_vm._l((_vm.moreMenu),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.link,"link":""}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title))],1)],1)}),1)],1)],1)])]):_vm._e(),_c('v-main',{class:{ 'scroll-container-app-bar' :_vm.$route.meta.type === 'mobile' },style:(_vm.$route.meta.type === 'mobile'
        ? `height: ${_vm.getWindowHeight}px; max-width: ${_vm.getWindowWidth}px!important; `
        : ''),attrs:{"id":"scrolling-techniques-4"}},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }