import Role from '../../services/role.api';

const state = {};

const mutations = {};

const getters = {};

const actions = {
  getRoles: () =>
    Role.getRoles()
      .then(({ data }) => data)
      .catch((err => console.error(err)))
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
