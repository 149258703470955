import httpClient from './httpClient';

const baseEndPoint = '/get-icons';

const getIcons = () => httpClient.get(`${baseEndPoint}`)


export default {
    getIcons
};
