const deviceEventListenerFormEs = {
  titleFormCreate: "Crear nuevo observador",
  titleFormEdit: "Crear nuevo observador",
  name: "Nombre",
  listenerType: "Tipo de observador",
  reference: "Referencia",
  generateReference: "Generar referencia",
  description: "Descripción",
  communicationChannels: "Canales de comunicación",
  users: "Usuarios",
  timeout: "Timeout",
};

export default {
  deviceEventListenerFormEs,
};
