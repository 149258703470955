import templateForm from "./templateForm";
import templateTab from "./templateTab";
import templateDetail from "./templateDetail";

const templateEs = {
  templateTableTitle: "Templates",
  templateForm: templateForm.templateFormEs,
  templateTab: templateTab.templateTabEs,
  templateDetail: templateDetail.templateDetailEs,
};

export default {
  templateEs,
};
