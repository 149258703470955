import Vehicle from '../../services/vehicle.api'

const state = {}
const mutations = {}
const getters = {}
const actions = {
 
    getVehicle: (_store, id) =>
        Vehicle.getVehicle(id)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    getVehicles: (_store, { page, filter }) =>
        Vehicle.getvehicles(page, filter)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    getFakeVehicles: () =>
        Vehicle.getFakeVehicles()
            .then(({ data }) => data)
            .catch((err => console.error(err)))

}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}