const eventListenersEn = {
    cardTitleCreate: 'Create event listener',
    cardSubtitleCreate: 'Create a new event listener',
    cardTitleEdit: 'Edit event listener',
    cardSubtitleEdit: 'Edit event listener',
}

const eventListenersEs = {
    cardTitleCreate: 'Crear listener',
    cardSubtitleCreate: 'Crear nuevo listener de eventos',
    cardTitleEdit: 'Editar listener',
    cardSubtitleEdit: 'Editar nuevo listener de eventos',
}

export default {
    eventListenersEn,
    eventListenersEs
}