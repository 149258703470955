const workerFormEs = {
  formTitleCreate: "Crear un nuevo trabajador",
  formTitleEdit: "Editar trabajador",
  rut: "Rut",
  name: "Nombre",
  lastName: "Apellido",
  alias: "Alias",
  dateOfBirth: "Fecha de nacimiento",
  weight: "Peso",
  height: "Altura",
  restingHeartRate: "Frecuencia cardíaca en reposo",
  department: "Departamento",
  contactNumber: "Número de contacto",
};

export default {
  workerFormEs,
};
