const deviceTabEs = {
  detail: "Detalles",
  configuration: "Configuración",
  slots: "Slots",
  monitoring: "Monitorizar",
  latestMonitoring: "Últimos datos",
  historicalMonitoring: "Datos históricos",
  events: "Eventos",
  eventListener: "Observadores",
  streaming: "Transmisión en vivo",
};

export default {
  deviceTabEs,
};
