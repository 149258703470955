const slotFormEs = {
  alias: "Nombre corto que haga referencia al slot (sensor) de forma rápida.",
  parameterName:
    "Identificador del parámetro utilizado en el ‘payload’ que envía el dispositivo a la plataforma. No puede tener espacios.",
  variable: "Tipo de variable física o derivada que mide el sensor.",
  unitOfMeasurement:
    "Cantidad estandarizada para determinar la magnitud del valor enviado por el dispositivo en el slot.",
  displayer:
    "Permite ver de manera gráfica el último dato o el histórico de datos del slot.",
};

export default {
  slotFormEs,
};
