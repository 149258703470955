const sidebarEn = {
    dashboard: 'Dashboard',
    systemUsers: 'System Users',
    departments: 'Departments',
    workers: 'Workers',
    devices: 'Devices',
    workspace: 'Workspace',
    template: 'Templates',
    notifications: 'Notifications',
    demo: 'Demo'
}

const sidebarEs = {
    dashboard: 'Tablero',
    systemUsers: 'Usuarios de sistema',
    departments: 'Departamentos',
    workers: 'Trabajadores',
    devices: 'Dispositivos',
    workspace: 'Espacio de trabajo',
    template: 'Plantillas',
    notifications: 'Notificaciones',
    demo: 'Demo'
}

export default {
    sidebarEn,
    sidebarEs,
}