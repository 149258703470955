import httpClient from './httpClient';

const baseEndPoint = '/device';

const getFunctionTypes = () =>
    httpClient.get("/get-function-types")

const getItem = (device, slot) =>
    httpClient.get(`${baseEndPoint}/${device}/slot/${slot}/calibrator`)

const sendItem = (device, slot, calibrator) =>
    httpClient.put(`${baseEndPoint}/${device}/slot/${slot}/calibrator`, calibrator)

export default {
    getFunctionTypes,
    getItem,
    sendItem
};
