const deviceFormEs = {
  identifier: "Texto identificador único e irrepetible del dispositivo.",
  alias: "Nombre corto que haga referencia al dispositivo de forma rápida.",
  radiusOfCoverage:
    "Alcance del dispositivo para transmitir datos en caso de ser inalámbrico",
  upLinkInterval:
    "Cada cuanto tiempo se espera que el dispositivo transmita en segundos (s). Permite definir el estado offline del dispositivo (luego de 2.5 veces el tiempo esperado).",
  initialPosition: "Lugar en que se encuentra el dispositivo siendo utilizado.",
  icon: "Elige un icono que represente tu dispositivo en los mapas. Puedes añadir cualquiera de https://materialdesignicons.com/ añadiendo el prefijo “mdi-”.",
  latLng:
    "Latitud y longitud del dispositivo como posición inicial. Puedes usar el mapa.",
  color:
    "Color del icono que se mostrará en los mapas para representar tu dispositivo.",
};

export default {
  deviceFormEs,
};
