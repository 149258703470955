import deviceForm from "./deviceForm";
import deviceTab from "./deviceTab";
import deviceDetail from "./deviceDetail";
import deviceEventListenerForm from "./deviceEventListenerForm";
import deviceMonitoring from "./deviceMonitoring";
import slot from "./slot/slot";

const deviceEs = {
  deviceTableTitle: "Dispositivos",
  neverSeen: "Nunca visto",
  streamingTitle: "Transmisión de eventos en vivo",
  deviceForm: deviceForm.deviceFormEs,
  deviceTab: deviceTab.deviceTabEs,
  deviceDetail: deviceDetail.deviceDetailEs,
  deviceEventListenerForm: deviceEventListenerForm.deviceEventListenerFormEs,
  deviceMonitoring: deviceMonitoring.deviceMonitoringEs,
  slot: slot.slotEs,
};

export default {
  deviceEs,
};
