import httpClient from './httpClient';

const baseEndPoint = '/v1/my-organization';

export const getOrganization = () =>
    httpClient.get(baseEndPoint);

export default {
    getOrganization,
};
