const slotListenerFormEs = {
  name: "Nombre del observador. Se utilizará en notificaciones.",
  listenerType:
    "Reglas de funcionamiento del dispositivo. Si la regla elegida se cumple, entonces se emite una notificación.",
  conditionType:
    "Condición matemática que debe cumplirse para notificar el evento.",
  value:
    "Valor que se utilizará para hacer la comparación con el valor calibrado.",
};

export default {
  slotListenerFormEs,
};
