import TimeZone from '../../services/timezone.api';

const state = {
};

const mutations = {};

const getters = {};

const actions = {
    getTimezones: () =>
        TimeZone.getTimezones()
            .then(({ data }) => data)
            .catch((err => console.error(err)))

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};