const deviceListenerForm = {
  name: "Nombre del observador. Se utilizará en notificaciones.",
  listenerType:
    "Reglas de funcionamiento del dispositivo. Si la regla elegida se cumple, entonces se emite una notificación.",
  reference:
    "Utilizado para referenciar los observadores de tipo ‘Ventanas de tiempo’ o ‘Personalizado’. Permite diferenciar mismos tipos usando la referencia. Debe ser único en el mismo dispositivo.",
  description:
    "Texto explicativo sobre el observador. Se utilizará en notificaciones.",
  channels: "Métodos de envío de notificaciones a los usuarios elegidos.",
  users:
    "Usuarios de la plataforma que recibirán notificaciones vía los canales de comunicación.",
  timeout:
    "Tiempo que debe transcurrir en un dispositivo sin enviar datos para establecer como apagado o fuera de linea.",
};

export default {
  deviceListenerForm,
};
