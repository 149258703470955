import axios from 'axios';
import store from '../store'
import router from '@/plugins/router'


export const httpClient = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/api' : process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
});
export const setHttpClientAuth = () => {
  httpClient.defaults.headers['Authorization'] = `Bearer ${store.getters['auth/getAuth']}`
}

export const setInterceptors = (vm) => {
  // interceptor to catch errors
  const errorInterceptor = error => {
    // check if it's a server error
    // if (!error.response) {
    //   vm.$toast.warning(vm.$t('toast.networkError'));
    //   return Promise.reject(error);
    // }

    // all the other error responses
    switch (error.response.status) {
      case 400:
        vm.$toast.error(
          error.response.data.i18n
            ? vm.$t(error.response.data.i18n)
            : vm.$t('toast.error400'),
        );
        break;
      case 403:
        router.push({ name: 'forbidden' })
        break;
      case 404: //page not found
        vm.$toast.error(
          error.response.data.message
            ? error.response.data.message
            : vm.$t('toast.error404'),
        );
        break;
      case 401: // authentication error, logout the user
        store.dispatch('auth/automaticLogout')
        break;
      case 500: // Server error
        vm.$toast.error(vm.$t('toast.unexpectedError'));
        break;

      default:
        vm.$toast.error(vm.$t('toast.unexpectedError'));
    }
    return Promise.reject(error);
  };

  httpClient.interceptors.response.use(undefined, errorInterceptor);
}

export default httpClient;