const userEs = {
  //Title
  userTableTitle: "Usuarios",
  //Row table headers
  nameRow: "Nombre",
  lastNameRow: "Apellido",
  emailRow: "Correo",
  timestampRow: "Timestamp",
  //Wizard title
  newUser: "Crear nuevo usuario",
  //Wizard headers
  userInformation: "Información de usuario",
  role: "Rol",
  createWorker: "Crear trabajador",
  passwordHeader: "Contraseña",
  //Wizard step 1 inputs
  name: "Nombre",
  lastName: "Apellido",
  phoneNumber: "Número de teléfono",
  email: "Correo",
  timeZone: "Zona horaria",
  //Wizard step 2 inputs
  roles: "Roles",
  //Wizard step 3 inputs
  workerQuestion: "¿Quieres crear un trabajador?",
  rut: "Rut",
  alias: "Alias",
  department: "Departamento",
  //Wizard step 4 inputs
  autogeneratePassword: "Autogenerar contraseña y enviarla por correo",
  password: "Contraseña",
};
export default {
  userEs,
};
