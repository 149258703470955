import httpClient from './httpClient';

const baseEndPoint = '/notification';

const getLatestNotifications = () =>
    httpClient.get('latest-notifications');
const getNotifications = (page, filter) =>
    httpClient.get(`${baseEndPoint}s`, { params: { page, filter } });
const getNotificationsV1 = (options) =>
    httpClient.get(`v1${baseEndPoint}s`, { params: options });
const deleteNotificationsV1 = (notifications) =>
    httpClient.post(`v1${baseEndPoint}s/delete`, notifications);
const markAsSeenNotificationsV1 = (notifications) =>
    httpClient.post(`v1${baseEndPoint}s/mark-as-seen`, notifications);
const markAsNotSeenNotificationsV1 = (notifications) =>
    httpClient.post(`v1${baseEndPoint}s/mark-as-not-seen`, notifications);
const getNotification = (notification) =>
    httpClient.get(`${baseEndPoint}/${notification}`);
const notificationDemo = (notification) =>
    httpClient.post(`${baseEndPoint}-demo`, notification);

export default {
    getLatestNotifications,
    getNotifications,
    getNotificationsV1,
    deleteNotificationsV1,
    markAsSeenNotificationsV1,
    markAsNotSeenNotificationsV1,
    getNotification,
    notificationDemo,
};
