import httpClient from './httpClient';

const baseEndPoint = '/permissions';

const getPermissions = () =>
    httpClient.get(baseEndPoint);
const getUserPermissions = () =>
    httpClient.get('/user-permissions');
const getRoles = () =>
    httpClient.get('/roles');

export default {
    getPermissions,
    getRoles,
    getUserPermissions,
};
