import ProximityEvent from '../../services/proximityEvent.api';

const state = {};

const mutations = {};

const getters = {};

const actions = {
    getEvents: (_store, page) =>
        ProximityEvent.getEvents(page)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};