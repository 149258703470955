import { dummyannotations } from "../../components/Monitoring/PlotAnnotations/dummydata";

const state = {

};

const mutations = {

};

const getters = {};

const actions = {
    /**
     * Action para obtener listado de anotaciones
     * @param {*} context
     * @param {*} requestAnnotations objeto con .slotid .startdate .finishdate
     */
    getAnnotations() {
        return dummyannotations
    },
    updateAnnotation(){
        
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
