import router from '@/plugins/router'
import axios from 'axios';
import { setHttpClientAuth } from '../services/httpClient'
import Vue from 'vue'
import i18n from '../plugins/i18n';



export default {
    namespaced: true,
    state: {
        token: null
    },
    mutations: {
        setToken: (state, payload) => {
            state.token = payload
        }
    },
    actions: {
        login: ({ commit }, payload) => {
            axios.post(process.env.NODE_ENV === 'production' ? '/api/login' : `${process.env.VUE_APP_API_URL}/login`, payload.user, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(({ data }) => {
                    commit('setToken', data.access_token)
                    localStorage.setItem('token', data.access_token)
                    setHttpClientAuth()
                    if (data.user.needs_to_change_password) {
                        router.replace({ name: "changePassword" })
                    }
                    if (payload.route.query.redirect) {
                        router.push(payload.route.query.redirect)
                    } else {
                        router.replace({ name: "dashboard" })
                    }
                }).catch((err) => {
                    Vue.$toast.error(i18n.t('toast.loginError'), {
                        timeout: 2000
                    });
                    console.error(err.response.data)
                });
        },
        mobileLogin: ({ commit }, payload) => {
            axios.post(process.env.NODE_ENV === 'production' ? '/api/login' : 'https://sandbox.thegrouplab.com/api/login', payload.user, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(({ data }) => {
                    commit('setToken', data.access_token)
                    localStorage.setItem('token', data.access_token)
                    setHttpClientAuth()
                    if (data.user.needs_to_change_password) {
                        router.replace({ name: "changePassword" })
                    }
                    if (payload.route.query.redirect) {
                        router.push(payload.route.query.redirect)
                    } else {
                        router.replace({ name: "mobileMap" })
                    }
                }).catch((err) => {
                    Vue.$toast.error(i18n.t('toast.loginError'), {
                        timeout: 2000
                    });
                    console.error(err.response.data)
                });
        },

        getToken: ({ commit }) => {
            const token = localStorage.getItem('token') //null if token doesn't exists
            commit('setToken', token)
            return token
        },


        logout: ({ commit }) => {
            localStorage.removeItem('token')
            commit('setToken', null)
            router.push({ name: 'login' })
        },

        automaticLogout: ({ commit }) => {
            if (window.location.pathname !== '/login') {
                localStorage.removeItem('token')
                commit('setToken', null)
                router.push({
                    name: "login", query: {
                        redirect: window.location.pathname
                    }
                })
            }
        }
    },
    getters: {
        getAuth: (state) => state.token
    }
}