import Summary from '@/services/summary.api'

const state = {};

const mutations = {};

const getters = {};

const actions = {
    getDashboardSummary: () =>
        Summary.getDashboardSummary()
            .then(({ data }) => data)
            .catch((err => console.error(err)))

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}