import httpClient from './httpClient';

const baseEndPoint = '/v1/department';

const getDepartments = (options) =>
    httpClient.get(`${baseEndPoint}s`, { params: options })

const getDepartmentsTable = (page, filter) =>
    httpClient.get(`${baseEndPoint}-table`, { params: { page: page, filter: filter } })


const deleteDepartament = (department) =>
    httpClient.delete(`${baseEndPoint}/${department}`)


const getDepartment = (id) =>
    httpClient.get(`${baseEndPoint}/${id}`)


const createDepartment = (name, description) =>
    httpClient.post(`${baseEndPoint}`, {
        name: name,
        description: description
    })


const editDepartment = (id, name, description) =>
    httpClient.put(`${baseEndPoint}/${id}`, {
        id: id,
        name: name,
        description: description
    })


export default {
    getDepartments,
    deleteDepartament,
    getDepartment,
    createDepartment,
    editDepartment,
    getDepartmentsTable
};