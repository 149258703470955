import Account from '../../services/account.api';

const state = {
};

const mutations = {};

const getters = {};

const actions = {
    uploadProfilePhoto: (_store, profilePhoto) =>
        Account.uploadProfilePhoto(profilePhoto)
            .then(({ data }) => data)
            .catch((err => console.error(err)))

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};