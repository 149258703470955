import httpClient from './httpClient';

const baseEndPoint = '/notification-channel';

const getNotificationChannels = () =>
    httpClient.get(`${baseEndPoint}s`);

export default {
    getNotificationChannels,
};
