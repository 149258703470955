import httpClient from './httpClient';

const baseEndPoint = '/v1/display-modes';

const getDisplayModes = () =>
    httpClient.get(`${baseEndPoint}`)


export default {
    getDisplayModes
};
