import Events from "../../services/events.api";

const state = {};

const mutations = {};

const getters = {};

const actions = {
  getDowntimeEvents: (_store, { identifier, options }) =>
    Events.getDowntimeEvents(identifier, options)
      .then(({ data }) => data)
      //comment this line to use real downtime data
      //.then(() => MOCK_DATA)
      .catch((err) => console.error(err)),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

const MOCK_DATA = [
  {
    type: null,
    timestamp: "2022-01-26 12:06:40",
    listenerId: 6,
    eventListenerName: "Downtime Office",
    customData: "{}",
  },
  {
    type: null,
    timestamp: "2022-01-26 12:08:00",
    listenerId: 6,
    eventListenerName: "Downtime Office",
    customData: "{}",
  },
  {
    type: null,
    timestamp: "2022-01-26 12:32:52",
    listenerId: 6,
    eventListenerName: "Downtime Office",
    customData: "{}",
  },
];
