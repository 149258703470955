import workspaceTab from "./workspaceTab"
import dashboard from "./dashboard/dashboard"
import geofence from "./geofence/geofence"

const workspaceEs = {
    workspaceTab: workspaceTab.workspaceTabEs,
    dashboard: dashboard.dashboardEs,
    geofence: geofence.geofenceEs
}

export default{
    workspaceEs
}