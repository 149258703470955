import httpClient from './httpClient';

const baseEndPoint = 'device';

const getDisplayModes = () =>
    httpClient.get("/v1/display-modes")

const getVariables = () =>
    httpClient.get("/get-variables")

const getUnitsOfMeasurement = () =>
    httpClient.get("/get-units-of-measurement")

const getSlot = (device, slot) =>
    httpClient.get(`/v1/${baseEndPoint}/${device}/slot/${slot}`)

const createSlot = (deviceId, slot) =>
    httpClient.post(`/v1/${baseEndPoint}/${deviceId}/slot/`, slot)

const editSlot = (slot) =>
    httpClient.put(`/v1/${baseEndPoint}/${slot.device}/slot/`, slot)

const deleteSlot = (device, slot) =>
    httpClient.delete(`/v1/${baseEndPoint}/${device}/slot/${slot}`)

const getSlots = (device, options) =>
    httpClient.get(`/v1/${baseEndPoint}/${device}/slots`, { params: options })

export default {
    getDisplayModes,
    getUnitsOfMeasurement,
    getVariables,
    getSlot,
    createSlot,
    editSlot,
    deleteSlot,
    getSlots
};
