export default function auth({ to, from, next }) {
    if (!localStorage.getItem('token')) {
        return next({
            name: 'login',
            query: {
                redirect: to.fullPath
            }
        });
    }
    return next();
}