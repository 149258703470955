const filtersEn = {
    email: 'Filter by email',
    by: 'Filter by',
}

const filtersEs = {
    email: 'Filtrar por email',
    by: 'Filtrar por',
}

export default {
    filtersEn,
    filtersEs
}