import Events from "../../services/events.api";
const state = {};

const mutations = {};

const getters = {};

const actions = {
  getDowntimeEvents: (_store, { identifier, options }) =>
    Events.getDowntimeEvents(identifier, options)
      .then(({ data }) => data)
      .catch((err) => console.error(err)),
  getAllEvents: (_store, { identifier, options }) =>
    Events.getAllEvents(identifier, options)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => console.error(err)),
  getEvents: (_store, { identifier, options }) =>
    Events.getEvents(identifier, options)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => console.error(err)),
  getSlotEvents: (_store, { identifier, options }) =>
    Events.getSlotEvents(identifier, options)
      .then(({ data }) => data)
      .catch((err) => console.error(err)),
  getEvent: (_store, { eventId }) =>
    Events.getEvent(eventId)
      .then(({ data }) => data)
      .catch((err) => console.error(err)),
  getLatestTrip: (_store, { identifier }) =>
    Events.getLatestTrip(identifier)
      .then(({ data }) => data)
      .catch((err) => console.error(err)),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
