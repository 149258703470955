const websocketEn = {

}

const websocketEs = {
    connected: 'Conectado',
    disconnected: 'Desconectado'
}

export default {
    websocketEn,
    websocketEs
}