import Template from '../../services/template.api'

const state = {};

const mutations = {};

const getters = {};

const actions = {
    getTemplate: (_store, template) =>
        Template.getTemplate(template)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    getTemplates: (_store, options) =>
        Template.getTemplates(options)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    createTemplate: (_store, { device, template }) =>
        Template.createTemplate(device, template)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    createDeviceFromTemplate: (_store, { deviceTemplate, device }) =>
        Template.createDeviceFromTemplate(deviceTemplate, device)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    deleteTemplate: (_store, template) =>
        Template.deleteTemplate(template)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};