import Slot from '../../services/slot.api'

const state = {};

const mutations = {};

const getters = {};

const actions = {
    getDisplayModes: () =>
        Slot.getDisplayModes()
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    getUnitsOfMeasurement: () =>
        Slot.getUnitsOfMeasurement()
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    getVariables: () =>
        Slot.getVariables()
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    getSlot: (_store, { device, slot }) =>
        Slot.getSlot(device, slot)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    createSlot: (_store, slot) =>
        Slot.createSlot(slot.deviceId, slot.requestBody)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    editSlot: (_store, slot) =>
        Slot.editSlot(slot)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    deleteSlot: (_store, { device, slot }) =>
        Slot.deleteSlot(device, slot)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    getSlots: (_store, { device, options }) =>
        Slot.getSlots(device, options)
            .then(({ data }) => data)
            .catch((err => console.error(err)))

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};