import httpClient from './httpClient';

const baseEndPoint = '/v1/file-manager';

const uploadFile = (file) => httpClient.post(`${baseEndPoint}/dynamic_forms`, file)


export default {
    uploadFile
};
