import Device from '../../services/device.api';

const state = {};

const mutations = {};

const getters = {};

const actions = {
  getDevicesWithPosition: () =>
    Device.getDevicesWithPosition()
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  getLastPosition: (_store, device) =>
    Device.getLastPosition(device)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  createDevice: (_store, device) =>
    Device.createDevice(device)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  editDevice: (_store, device) =>
    Device.editDevice(device)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  deleteDevice: (_store, device) =>
    Device.deleteDevice(device)
      .then(({ data }) => data).catch((err) => console.error(err)),
  getDevices: (_store, options) =>
    Device.getDevices(options)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  getDevice: (_store, device) =>
    Device.getDevice(device)
      .then(({ data }) => data)
      .catch((err => console.error(err)))

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};