const state = {
    windowHeight: 1,
    windowWidth: 0,
    orientationScreen: 0,
};

const mutations = {
    setWindowHeight: (state) => { state.windowHeight = window.innerHeight },
    setWindowWidth: (state) => { state.windowWidth = window.innerWidth },
    setOrientation(state) { state.orientationScreen = window.orientation }
};

const getters = {
    getWindowHeight(state) { return state.windowHeight },
    getWindowWidth(state) { return state.windowWidth },
    getOrientation(state) { return state.orientationScreen }

};

const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};