const slotFormEs = {
  titleFormCreate: "Crear un nuevo slot",
  alias: "Alias",
  parameterName: "Nombre de parámetro",
  variable: "Variable",
  unitOfMeasurement: "Unidad de medida",
  latest: "Último dato",
  historical: "Histórico de datos",
};

export default {
  slotFormEs,
};
