const toastEn = {
    createSuccess: 'The new record was created successfully',
    editSuccess: 'The information was updated successfully',
    deleteSuccess: 'The information was deleted successfully',
    checkFormRequirements: 'Please check the form carefully for errors',
    networkError: 'Network or server error, please try again',
    unexpectedError: 'An unexpected error has occurred, please try again',
    sessionError: 'Session timed out, please login again',
    serverError: 'An error has occurred on the server, please try again',
    error404: 'The resource was not found',
    error400: 'The content of the request is incorrect',
    mailSuccess: 'The email was sent successfully',
    loginError: 'Invalid credentials',
}

const toastEs = {
    createSuccess: 'El nuevo registro fue creado satisfactoriamente',
    editSuccess: 'La información ha sido actualizada correctamente',
    deleteSuccess: 'La información ha sido eliminada correctamente',
    checkFormRequirements: 'Favor de verificar los requisitos del formulario',
    networkError: 'Error de red o de servidor, favor de intentar nuevamente',
    unexpectedError: 'Se ha producido un error inesperado, favor de intentar nuevamente',
    sessionError: 'Se agotó el tiempo de la sesión, favor de iniciar sesión nuevamente',
    serverError: 'Ha ocurrido un error en el servidor, favor de intentar nuevamente',
    error404: 'El recurso no pudo ser encontrado',
    error400: 'El contenido de la petición es incorrecto',
    mailSuccess: 'Mail enviado correctamente',
    loginError: 'Credenciales invalidas',
}

export default {
    toastEn,
    toastEs
}