import Login from '../../services/login.api';

const state = {
};

const mutations = {};

const getters = {};

const actions = {
    forgotPassword: (_store, email) =>
        Login.forgotPassword(email)
            .then(({ data }) => data)
            .catch(err => console.error(err)),
    resetPassword: (_store, options) =>
        Login.resetPassword(options)
            .then(({ data }) => data)
            .catch((err) => console.error(err))
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};