<template>
  <md-toolbar md-elevation="0" class="md-transparent">
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ $t(`title.${$route.meta.translate}`) }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <md-list>
            <md-list-item>
              <div class="row justify-center align-center">
                <v-icon
                  :color="this.$tgcWebsocket.isConnected() ? 'green' : 'red'"
                  size="15"
                  class="mr-1"
                  >mdi-circle</v-icon
                ><span class="mb-1"
                  >ws:
                  {{
                    this.$tgcWebsocket.isConnected()
                      ? $t("websocket.connected")
                      : $t("websocket.disconnected")
                  }}</span
                >
              </div>
            </md-list-item>

            <li class="md-list-item">
              <div class="md-list-item-content">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <md-button
                      slot="title"
                      class="md-button md-just-icon md-simple"
                      color="primary"
                      data-toggle="dropdown"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <md-icon>notifications</md-icon>
                      <span
                        v-if="
                          $store.getters[
                            'notification/unreadNotificationsQuantity'
                          ] > 0
                        "
                        class="notification"
                        >{{
                          $store.getters[
                            "notification/unreadNotificationsQuantity"
                          ]
                        }}</span
                      >
                      <p class="hidden-lg hidden-md">Notifications</p>
                    </md-button>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="notification in $store.getters[
                        'notification/notifications'
                      ]"
                      :key="notification.id"
                    >
                      <v-list-item-title
                        ><router-link
                          :to="'/notification/' + notification.id + '/details'"
                          ><span :class="{ bold: !notification.seen }">{{
                            notification.message.length > 20
                              ? notification.message.slice(0, 20) + "..."
                              : notification.message
                          }}</span></router-link
                        ></v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item>
                      <router-link class="w-100 text-center" to="/notifications"
                        >See all</router-link
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </li>

            <md-list-item>
              <router-link :to="{ name: 'myInfo' }">
                <i class="material-icons">person</i>
                <p class="hidden-lg hidden-md">Profile</p>
              </router-link>
            </md-list-item>

            <md-list-item v-on:click.prevent="logout">
              <i class="material-icons">power_settings_new</i>
              <p class="hidden-lg hidden-md">power_settings_new</p>
            </md-list-item>
            <form
              ref="form"
              id="logout-form"
              action="/logout"
              method="POST"
              style="display: none"
            ></form>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      selectedEmployee: null,
      employees: [
        "Jim Halpert",
        "Dwight Schrute",
        "Michael Scott",
        "Pam Beesly",
        "Angela Martin",
        "Kelly Kapoor",
        "Ryan Howard",
        "Kevin Malone",
      ],
    };
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
    insertNotification({ body }) {
      this.$store.commit("notification/ADD_NOTIFICATION", body);
      this.$toast.info(body.message);
    },
    subscribe() {
      this.$tgcWebsocket.subscribe(
        `user.${this.user.id}.notifications`,
        this.insertNotification
      );
    },
    unsubscribe() {
      this.$tgcWebsocket.unsubscribe(
        `user.${this.user.id}.notifications`,
        this.insertNotification
      );
    },
  },
  computed: {
    ...mapGetters("user", ["user"]),
  },
  watch: {
    "user.id": {
      handler(id) {
        if (id !== 0) this.subscribe();
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>
