import httpClient from './httpClient'

const baseEndPoint = '/device'

const getDeviceListenerTypes = () =>
    httpClient.get('/device-listener-types');
const getSlotListenerTypes = () =>
    httpClient.get('/slot-listener-types');
const getConditionTypes = () =>
    httpClient.get('/condition-types')

const getListenerReference = (device, listenerName) => httpClient.get(`/v1/device/${device}/listeners/reference-suggestion?listenerName=${listenerName}`);

const getDeviceListener = (device, listener) =>
    httpClient.get(`${baseEndPoint}/${device}/listener/${listener}`)
const getDeviceListeners = (device, options) =>
    httpClient.get(`/v1${baseEndPoint}/${device}/listeners`, { params: options })
const storeDeviceListener = (device, listener) =>
    httpClient.post(`${baseEndPoint}/${device}/listener`, listener)
const updateDeviceListener = (device, listener) =>
    httpClient.put(`${baseEndPoint}/${device}/listener`, listener)
const deleteDeviceListener = (device, listener) =>
    httpClient.delete(`${baseEndPoint}/${device}/listener/${listener}`)

const getSlotListener = (device, slot, listener) =>
    httpClient.get(`${baseEndPoint}/${device}/slot/${slot}/listener/${listener}`)
const getSlotListeners = (device, slot, options) =>
    httpClient.get(`/v1${baseEndPoint}/${device}/slot/${slot}/listeners`, { params: options })
const storeSlotListener = (device, slot, listener) =>
    httpClient.post(`${baseEndPoint}/${device}/slot/${slot}/listener`, listener)
const updateSlotListener = (device, slot, listener) =>
    httpClient.put(`${baseEndPoint}/${device}/slot/${slot}/listener`, listener)
const deleteSlotListener = (device, slot, listener) =>
    httpClient.delete(`${baseEndPoint}/${device}/slot/${slot}/listener/${listener}`)

const getItem = (device, slot) =>
    httpClient.get(`${baseEndPoint}/${device}/slot/${slot}`)

const createItem = (device, name, listenerTypeId, isActive) =>
    httpClient.post(`${baseEndPoint}/${device}/slot/`, {
        name: name,
        listener_type_id: listenerTypeId,
        is_active: isActive,
    })

const editItem = (device, id, name, listenerTypeId, isActive) =>
    httpClient.put(`${baseEndPoint}/${device}/slot/`, {
        id: id,
        name: name,
        listener_type_id: listenerTypeId,
        is_active: isActive,
    })

const deleteSlot = (device, slot) =>
    httpClient.delete(`${baseEndPoint}/${device}/slot/${slot}`)


export default {
    getListenerReference,
    getDeviceListenerTypes,
    getSlotListenerTypes,
    getDeviceListeners,
    storeDeviceListener,
    updateDeviceListener,
    deleteDeviceListener,
    getConditionTypes,
    getSlotListeners,
    storeSlotListener,
    updateSlotListener,
    deleteSlotListener,
    getDeviceListener,
    getSlotListener,
    getItem,
    createItem,
    editItem,
    deleteSlot
}
