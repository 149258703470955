import userForm from "./userForm";
import departmentForm from "./departmentForm";
import workerForm from "./workerForm";
import deviceForm from "./deviceForm";
import slotForm from "./slotForm";
import geofenceForm from "./geofenceForm";
import deviceListenerForm from "./deviceListenerForm";
import slotListenerForm from "./slotListenerForm";
import dashboardForm from "./dashboardForm";
import templateForm from "./templateForm";
import deviceTemplateForm from "./deviceTemplateForm";

const tooltipsEs = {
  userForm: userForm.userFormEs,
  departmentForm: departmentForm.departmentFormEs,
  workerForm: workerForm.workerFormEs,
  deviceForm: deviceForm.deviceFormEs,
  slotForm: slotForm.slotFormEs,
  geofenceForm: geofenceForm.geofenceFormEs,
  deviceListenerForm: deviceListenerForm.deviceListenerForm,
  slotListenerForm: slotListenerForm.slotListenerFormEs,
  dashboardForm: dashboardForm.dashboardFormEs,
  templateForm: templateForm.templateFormEs,
  deviceTemplateForm: deviceTemplateForm.deviceTemplateFormEs,
};

export default {
  tooltipsEs,
};
