import Vue from 'vue';
import VueRouter from "vue-router";
import routes from "../routes/routes";
import middlewarePipeline from '../routes/middlewarePipeline';
import i18n from '../plugins/i18n';
import store from '../store';

Vue.use(VueRouter);
// configure router
const router = new VueRouter({
    mode: 'history',
    routes,
    linkExactActiveClass: "nav-item active"
});
router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? `${i18n.t(to.meta.title)} | The Group Cloud` : 'The Group Cloud';

    if (!to.meta.middleware) {
      return next();
    }
    const middleware = to.meta.middleware;
  
    const context = {
      to,
      from,
      next,
      store,
    };
    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1),
    });
  });
export default router;