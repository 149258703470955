import DeviceType from '../../services/deviceType.api';

const state = {};

const mutations = {};

const getters = {};

const actions = {
    getDeviceTypes: () =>
        DeviceType.getDeviceTypes()
            .then(({ data }) => data)
            .catch((err => console.error(err)))
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};