export const rut = value => {
    if (value.length > 2) {
        let rut = value.replace(/[. ,-]+/g, '');
        let sRut1 = rut;
        let nPos = 0;
        let sInvertido = '';
        let sRut = '';
        for (let i = sRut1.length - 1; i >= 0; i--) {
            sInvertido += sRut1.charAt(i);
            if (i == sRut1.length - 1) {
                sInvertido += '-';
            } else if (nPos == 3) {
                sInvertido += '.';
                nPos = 0;
            }
            nPos++;
        }
        for (let j = sInvertido.length - 1; j >= 0; j--) {
            if (sInvertido.charAt(sInvertido.length - 1) != '.') {
                sRut += sInvertido.charAt(j);
            } else if (j != sInvertido.length - 1) {
                sRut += sInvertido.charAt(j);
            }
        }
        return sRut.toUpperCase();
    } else {
        return value;
    }
};

export const unixToStringDate = value => value ? new Date(value).toISOString().substr(0, 10) : null;

export const capitalize = value => {
    if (typeof value !== 'string') return '';
    return value ? value.charAt(0).toUpperCase() + value.slice(1) : null;
};

export const capitalizeAll = value => {
    if (typeof value !== 'string') return '';
    return value.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}

export const floatNumber = value => {
    value = value.replace(/^(\d+),(\d)/gm, "$1.$2")
    return value
}

export default {
    rut,
    unixToStringDate,
    capitalize,
    capitalizeAll,
    floatNumber,
}