const slotTabEs = {
  detail: "Detalles",
  calibrator: "Calibrador",
  monitoring: "Monitorizar",
  eventListener: "Observadores",
  streaming: "Transmisión en vivo",
};

export default {
  slotTabEs,
};
