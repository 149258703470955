const state = {
    ws: null,
    messages: []
};

const mutations = {
    WS: (state, ws) => state.ws = ws,
    ADD_MESSAGE: (state, message) => state.messages.push({ message, timestamp: new Date() })
};

const getters = {
    ws: ({ ws }) => ws,
    messages: ({ messages }) => messages,
};

const actions = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};