
export default {
    namespaced: true,
    state: {
        isMobile: false,
        version: 'mobile'
    },
    mutations: {
        changeIsMobile: (state, payload) => {
            state.isMobile = payload
        },
        changeVersion: (state, payload) => {
            state.version = payload
        }
    },
    actions: {

    },
    getters: {
        getIsMobile: (state) => state.isMobile,
        getVersion: (state) => state.version,
    }
}