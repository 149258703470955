const errorMessagesEn = {
    usedListenerReference: "There is already a event listener for this device with that reference"
}

const errorMessagesEs = {
    usedListenerReference: "La refenercia ya está en uso por otro event listener del dispositivo"
}

export default {
    errorMessagesEn,
    errorMessagesEs
}