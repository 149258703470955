import Monitoring from '../../services/monitoring.api';

const state = {};

const mutations = {};

const getters = {};

const actions = {
    getSlotMeasurement: (_store, deviceSlotId) =>
        Monitoring.getSlotMeasurement(deviceSlotId)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    getMeasurements: (_store, { deviceSlotId, from, to }) =>
        Monitoring.getMeasurements(deviceSlotId, from, to)
            .then(({ data }) => data)
            .catch((err => console.error(err)))

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};