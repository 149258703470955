import Notification from '../../services/notification.api';

const state = {
  notifications: [],
  unreadNotificationsQuantity: 0,
};

const mutations = {
  NOTIFICATIONS(state, notifications) { state.notifications = notifications },
  ADD_NOTIFICATION(state, notification) {
    state.notifications.unshift(notification);
    if (state.notifications.length >= 10) {
      state.notifications.pop();
    }
    state.unreadNotificationsQuantity++;
  },
  UNREAD_NOTIFICATIONS_QUANTITY(state, unreadNotificationsQuantity) { state.unreadNotificationsQuantity = unreadNotificationsQuantity },
  READ_NOTIFICATION(state, notification) {
    if (!notification.seen) {
      state.unreadNotificationsQuantity--
    }
    const n = state.notifications.find(item => item.id === notification.id)
    if (n) n.seen = true
  }
};

const getters = {
  notifications: state => state.notifications,
  unreadNotificationsQuantity: state => state.unreadNotificationsQuantity,
};

const actions = {
  getNotifications: (_store, { page, filter }) =>
    Notification.getNotifications(page, filter)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  getNotificationsV1: (_store, options) =>
    Notification.getNotificationsV1(options)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  deleteNotificationsV1: (_store, notifications) =>
    Notification.deleteNotificationsV1(notifications)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  markAsSeenNotificationsV1: (_store, notifications) =>
    Notification.markAsSeenNotificationsV1(notifications)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  markAsNotSeenNotificationsV1: (_store, notifications) =>
    Notification.markAsNotSeenNotificationsV1(notifications)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  getLatestNotifications: ({ commit, state }) => {
    const from = state.notifications.length !== 0 ? new Date(state.notifications[0].timestamp).toString().split(' (')[0] : new Date().toString().split(' (')[0];
    Notification.getLatestNotifications(from)
      .then(({ data }) => {
        commit('NOTIFICATIONS', data.notifications);
        commit('UNREAD_NOTIFICATIONS_QUANTITY', data.unread_notifications_quantity);
        return data
      })
      .catch((err => console.error(err)))
  },
  getNotification: ({ state, commit }, notification) =>
    Notification.getNotification(notification)
      .then(({ data }) => {
        commit('READ_NOTIFICATION', data)
        return data
      })
      .catch((err => console.error(err)))
  ,
  notificationDemo: (_store, notification) =>
    Notification.notificationDemo(notification)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
