import DisplayModes from '../../services/displayModes.api';

const state = {};

const mutations = {};

const getters = {};

const actions = {
    getDisplayModes: () =>
        DisplayModes.getDisplayModes()
            .then(({ data }) => data)
            .catch((err => console.error(err)))

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};