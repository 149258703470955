import i18n from '../plugins/i18n';
export const required = (value, errorMessage = i18n.t('validationMessages.required')) =>
  (value !== null && value !== '') || errorMessage;
export const password = value =>
  value.length >= 6 || i18n.t('validationMessages.password');
export const optionalPassword = value =>
  (value.length >= 6 || value.length === 0) || i18n.t('validationMessages.password');
export const passwordConfirm = (value, passwordConfirm) =>
  (value == passwordConfirm) || i18n.t('validationMessages.passwordConfirm');
export const expirationDate = (
  value,
  dateOfIssue,
  errorMessage = i18n.t('validationMessages.expiration'),
) => {
  if (value != '' && value != null && dateOfIssue != '') {
    const valueSplit = value.split('-');
    const dateOfIssueSplit = dateOfIssue.split('-');
    const issue = new Date(Number(dateOfIssueSplit[0]), Number(dateOfIssueSplit[1]) - 1, Number(dateOfIssueSplit[2])).getTime();
    const expirationDate = new Date(Number(valueSplit[2]), Number(valueSplit[1]) - 1, Number(valueSplit[0])).getTime();
    if (issue < expirationDate) {
      return true;
    } else {
      return errorMessage;
    }
  } else {
    return true;
  }
};
export const dateOfIssue = (
  value,
  errorMessage = i18n.t('validationMessages.issue'),
) => {
  if (value != '' && value != null) {
    const valueSplit = value.split('-');
    value = `${valueSplit[2]}-${valueSplit[1]}-${valueSplit[0]}`;
    const today = new Date().getTime();
    const dateOfIssue = new Date(value).getTime();
    if (dateOfIssue <= today) {
      return true;
    } else {
      return errorMessage;
    }
  } else {
    return true;
  }
};
export const email = value =>
  /^(([^<>()[\]\\.,; =\s@"]+(\.[^<>()[\]\\.,; =\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  ) || i18n.t('validationMessages.email');
const dv = T => {
  let M = 0,
    S = 1;
  for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
  return S ? S - 1 : 'k';
};
export const rut = value => {
  // Valida el rut con su cadena completa "XXXXXXXX-X"
  let rutCompleto = value.replace(/[., -]+/g, '');
  let rut = rutCompleto.slice(0, -1);
  let digv = rutCompleto.slice(-1);
  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(`${rut}-${digv}`) || rut.length < 7)
    return i18n.t('validationMessages.rut');
  if (digv == 'K') {
    digv = 'k';
  }
  if (dv(rut) != digv) {
    return i18n.t('validationMessages.rut');
  }
  return true;
};
export const integer = value => /^\d+$/.test(value) || i18n.t('validationMessages.integer')
export const maxLength = (value, maxLength, defaultMessage = 'Es demasiado largo') => {
  if (value.length > maxLength) return defaultMessage;
  return true;
}
export const minLength = (value, minLength, defaultMessage = 'Es demasiado corto') => {
  if (value.length < minLength) return defaultMessage;
  return true;
}
export const max = (value, maxValue, defaultMessage = 'Es demasiado alto') => {
  if (value > maxValue) return defaultMessage;
  return true;
}
export const min = (value, minValue, defaultMessage = 'Es demasiado bajo') => {
  if (value < minValue) return defaultMessage;
  return true;
}
export default {
  rut,
  required,
  optionalPassword,
  password,
  passwordConfirm,
  expirationDate,
  dateOfIssue,
  email,
  integer,
  max,
  maxLength,
  min,
  minLength
}