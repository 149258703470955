const geofenceFormEs = {
  name: "Nombre único de la geocerca",
  description:
    "Explicación de lo que existe en la geocerca o para que es utilizada.",
  color: "Color de la geocerca que será mostrada en los mapas.",
};

export default {
  geofenceFormEs,
};
