const workerFormEs = {
  rut: "Número identificador único e irrepetible por persona",
  name: "Nombre del trabajador",
  lastName: "Apellido del trabajador",
  alias: "Nombre corto que haga referencia al trabajador de forma rápida",
  dateOfBirth: "Fecha de nacimiento del trabajador",
  weight: "Peso del trabajador en kilogramos (Kg)",
  height: "Altura del trabajador en metro (m)",
  restingHeartRate:
    "Indicador de frecuencia cardíaca del trabajador sin realizar actividad física",
  department: "Departamento en el cual se desempeña el trabajador.",
  contactNumber:
    "Teléfono de contacto, pueden llegar mensajes y notificaciones a este teléfono.",
};

export default {
  workerFormEs,
};
