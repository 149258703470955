import httpClient from './httpClient';

const baseEndPoint = '/monitoring/slot';

const getSlotMeasurement = (deviceSlotId) =>
    httpClient.get(`${baseEndPoint}/${deviceSlotId}/last-measurement`)

const getMeasurements = (deviceSlotId, from, to) =>
    httpClient.get(`${baseEndPoint}/${deviceSlotId}/get-measurements`, { params: { from, to } })

export default {
    getSlotMeasurement,
    getMeasurements
};
