<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>

    <side-bar
      :sidebar-item-color="getTheme"
    >
      <sidebar-link to="/dashboard" data-v-step="0">
        <md-icon>dashboard</md-icon>
        <p>{{ $t("sidebar.dashboard") }}</p>
      </sidebar-link>
      <sidebar-link
        to="/users"
        v-if="$store.getters.getPermission('users_view')"
      >
        <md-icon>manage_accounts</md-icon>
        <p>{{ $t("sidebar.systemUsers") }}</p>
      </sidebar-link>
      <sidebar-link
        to="/departments"
        v-if="$store.getters.getPermission('departments_view')"
      >
        <md-icon>group</md-icon>
        <p>{{ $t("sidebar.departments") }}</p>
      </sidebar-link>
      <sidebar-link
        to="/workers"
        v-if="$store.getters.getPermission('workers_view')"
      >
        <md-icon>assignment_ind</md-icon>
        <p>{{ $t("sidebar.workers") }}</p>
      </sidebar-link>
      <!-- <sidebar-link to="/vehicles">
        <md-icon>local_shipping</md-icon>
        <p>Vehicles</p>
      </sidebar-link> -->
      <sidebar-link
        to="/devices"
        v-if="$store.getters.getPermission('devices_view')"
        data-v-step="1"
      >
        <md-icon>device_hub</md-icon>
        <p>{{ $t("sidebar.devices") }}</p>
      </sidebar-link>
      <sidebar-link to="/workspace" v-if="workspace">
        <md-icon>settings</md-icon>
        <p>{{ $t("sidebar.workspace") }}</p>
      </sidebar-link>
      <sidebar-link
        to="/templates"
        v-if="$store.getters.getPermission('templates_view')"
      >
        <md-icon>description</md-icon>
        <p>{{ $t("sidebar.template") }}</p>
      </sidebar-link>
      <sidebar-link
        to="/contacts"
        v-if="$store.getters.getPermission('admin_contacts_view')"
      >
        <md-icon>mark_as_unread</md-icon>
        <p>Contacts</p>
      </sidebar-link>
      <!--sidebar-link to="/user">
        <md-icon>person</md-icon>
        <p>User Profile</p>
      </sidebar-link>
      <sidebar-link to="/table">
        <md-icon>content_paste</md-icon>
        <p>Table list</p>
      </sidebar-link>
      <sidebar-link to="/typography">
        <md-icon>library_books</md-icon>
        <p>Typography</p>
      </sidebar-link>
      <sidebar-link to="/icons">
        <md-icon>bubble_chart</md-icon>
        <p>Icons</p>
      </sidebar-link>
      <sidebar-link to="/maps">
        <md-icon>location_on</md-icon>
        <p>Maps</p>
      </sidebar-link-->
      <sidebar-link
        to="/notifications"
        v-if="$store.getters.getPermission('notifications_view')"
      >
        <md-icon>notifications</md-icon>
        <p>{{ $t("sidebar.notifications") }}</p>
      </sidebar-link>
      <sidebar-link to="/demo" v-if="$store.getters.getPermission('demo_view')">
        <md-icon>
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M7,2V4H8V18A4,4 0 0,0 12,22A4,4 0 0,0 16,18V4H17V2H7M11,16C10.4,16 10,15.6 10,15C10,14.4 10.4,14 11,14C11.6,14 12,14.4 12,15C12,15.6 11.6,16 11,16M13,12C12.4,12 12,11.6 12,11C12,10.4 12.4,10 13,10C13.6,10 14,10.4 14,11C14,11.6 13.6,12 13,12M14,7H10V4H14V7Z"
            />
          </svg>
        </md-icon>
        <p>{{ $t("sidebar.demo") }}</p>
      </sidebar-link>
      <sidebar-link
        to="/matriz"
        v-if="user.organization === 'Pucobre'"
      >
        <md-icon>apps</md-icon>
        <p>Matriz de nodos</p>
      </sidebar-link>
      <!-- <sidebar-link to="/test">
        <md-icon>notifications</md-icon>
        <p>Test</p>
      </sidebar-link> -->
      <mobile-menu slot="content"></mobile-menu>
      <li class="version">
        <p>version {{ version }}</p>
      </li>
    </side-bar>
    

    <div class="main-panel">
      <top-navbar></top-navbar>

      <!--fixed-plugin
        :color.sync="sidebarBackground"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin-->

      <dashboard-content> </dashboard-content>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
    <tgl-websocket />
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "../../pages/Layout/MobileMenu.vue";
import SidebarLink from "../../components/SidebarPlugin/SidebarLink.vue";
import { mapGetters } from "vuex";
import TglWebsocket from "@/components/Websocket/TglWebsocket";
// let mobile = require("is-mobile");

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    MobileMenu,
    SidebarLink,
    TglWebsocket,
  },
  data() {
    return {
      sidebarBackground: "ow",
    };
  },
  computed: {
    ...mapGetters(["getTheme"]),
    ...mapGetters("mobile", ["getVersion"]),
    ...mapGetters("user", ["user"]),
    version() {
      return process.env.VERSION;
    },
    workspace() {
      return this.$store.getters.getPermission("dashboards_view") ||
        this.$store.getters.getPermission("geofences_view")
        ? true
        : false;
    },
  },
  methods: {},

  mounted() {
    this.$store.dispatch("getUserPermissions");
    this.$store.dispatch("notification/getLatestNotifications");
    this.$store.dispatch("user/getUserInfo");
  },
};
</script>
<style scoped lang="sass">
@import '@/assets/sass/partials/version.sass'
</style>