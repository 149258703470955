import labels from '@/assets/translate/displayers/labels'
import hints from '@/assets/translate/displayers/hints'

const displayersEn = {
    labels: labels.labelsEn,
    hints: hints.hintsEn
}

const displayersEs = {
    labels: labels.labelsEs,
    hints: hints.hintsEs
}

export default {
    displayersEn,
    displayersEs
}