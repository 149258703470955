import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

// Plugins
import GlobalComponents from "./globalComponents"
import GlobalDirectives from "./globalDirectives"
import Notifications from "./components/NotificationPlugin"
import vuetify from './plugins/vuetify'
import store from './store'
import router from './plugins/router'
import i18n from './plugins/i18n'
// import _socket from './plugins/socket'
import Clipboard from 'v-clipboard'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import { setHttpClientAuth, setInterceptors } from './services/httpClient'

// import webSocket from './plugins/webSocket'
import TgcWebsocket from '@tgl/tgc-vue-websocket'
Vue.use(TgcWebsocket, {
  host: process.env.VUE_APP_WS_HOST,
  protocol: 'wss'
})
// webSocket.openConnection()

import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection)
//vue-tour plugin
// import VueTour from 'vue-tour'

// require('vue-tour/dist/vue-tour.css')

import tgcDynamicForms from "@tgl/tgc-dynamic-forms"
import validationRules from "./utils/validationRules"
import formatters from "./utils/formatter"

Vue.use(tgcDynamicForms, {
  validationRules,
  formatters
})

// Vue.use(VueTour)


const options = {
  position: 'top-right',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
}

Vue.use(Toast, options)
import { mapActions, mapMutations } from 'vuex'

// MaterialDashboard plugin
import MaterialDashboard from "./material-dashboard"
import 'leaflet/dist/leaflet.css'
import "leaflet-geosearch/dist/geosearch.css";
import 'leaflet-search/src/leaflet-search.css'

import Chartist from "chartist"

Vue.prototype.$Chartist = Chartist

let mobile = require('is-mobile')

Vue.use(MaterialDashboard)
Vue.use(GlobalComponents)
Vue.use(GlobalDirectives)
Vue.use(Notifications)
Vue.use(Clipboard)

new Vue({
  vuetify,
  router,
  store,
  i18n,
  data: {
    Chartist: Chartist
  },
  mounted() {
    this.changeIsMobile(mobile({ tablet: true }))
    this.getToken()
    // this.getTour()
    this.getLocalPermissions()
    setHttpClientAuth() //use this method when token exists (into login callback for example)
    setInterceptors(this)
  },
  methods: {
    ...mapActions("auth", ["getToken"]),
    // ...mapActions("tour", ["getTour"]),
    ...mapActions(["getLocalPermissions"]),
    ...mapMutations('mobile', ['changeIsMobile'])
  },
  render: h => h(App)
}).$mount('#app')
