<template>
  <v-app>
    <v-icon
      v-if="$route.meta.type === 'mobile' && enableHide"
      class="chevron-toggle"
      :class="
        isVisible && enableHide ? 'chevron-toggle-down' : 'chevron-toggle-up'
      "
      @click="isVisible = !isVisible"
      size="25"
      >{{ isVisible ? "mdi-chevron-down" : "mdi-chevron-up" }}</v-icon
    >
    <v-app-bar
      bottom
      fixed
      color="white"
      dark
      height="96"
      :shrink-on-scroll="!enableHide && enableShrink"
      :hide-on-scroll="enableHide"
      scroll-target="#scrolling-techniques-4"
      v-model="isVisible"
      class="app-bar-mobile"
      v-if="$route.meta.type === 'mobile'"
    >
      <div class="row h-100 w-100 mt-3">
        <div class="col-3 d-flex flex-column align-center justify-center">
          <router-link :to="{ name: 'mobileMap' }">
            <div class="col d-flex flex-column align-center justify-center">
              <v-icon
                class="app-bar-icon"
                :color="
                  $route.path.includes('/m/map') ? 'primary' : ' grey lighten-1'
                "
                >mdi-map-search-outline</v-icon
              >
              <span
                class="app-bar-icon-text mt-3"
                :style="
                  $route.path.includes('/m/map')
                    ? 'color: #23c577!important'
                    : 'color: #BDBDBD!important'
                "
                >Mapa</span
              >
            </div>
          </router-link>
        </div>
        <div class="col-3 d-flex flex-column align-center justify-center">
          <router-link :to="{ name: 'mobileDevice' }">
            <div class="col d-flex flex-column align-center justify-center">
              <v-icon
                class="app-bar-icon"
                :color="
                  $route.path.includes('/m/devices')
                    ? 'primary'
                    : ' grey lighten-1'
                "
                >mdi-sitemap</v-icon
              >
              <span
                class="app-bar-icon-text mt-3"
                :style="
                  $route.path.includes('/m/devices')
                    ? 'color: #23c577!important'
                    : 'color: #BDBDBD!important'
                "
                >Dispositivos</span
              >
            </div>
          </router-link>
        </div>
        <div class="col-3 d-flex flex-column align-center justify-center">
          <router-link :to="{ name: 'MobileNotificationTable' }">
            <div class="col d-flex flex-column align-center justify-center">
              <v-icon
                class="app-bar-icon"
                :color="
                  $route.path.includes('/m/notifications')
                    ? 'primary'
                    : ' grey lighten-1'
                "
                >mdi-bell-outline</v-icon
              >
              <span
                class="app-bar-icon-text mt-3"
                :style="
                  $route.path.includes('/m/notifications')
                    ? 'color: #23c577!important'
                    : 'color: #BDBDBD!important'
                "
                >Alertas</span
              >
            </div>
          </router-link>
        </div>
        <div class="col-3 d-flex flex-column align-center justify-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="col d-flex flex-column align-center justify-center">
                <v-icon
                  color="grey lighten-1"
                  data-v-step="3"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-dots-vertical</v-icon
                >
                <span
                  class="app-bar-icon-text mt-3"
                  style="color: #bdbdbd !important"
                  >Más</span
                >
              </div>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in moreMenu"
                :key="index"
                :to="item.link"
                link
              >
                <v-list-item-title
                  ><v-icon color="primary">{{ item.icon }}</v-icon>
                  {{ item.title }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-app-bar>
    <v-main
      id="scrolling-techniques-4"
      :class="{ 'scroll-container-app-bar' :$route.meta.type === 'mobile' }"
      :style="
        $route.meta.type === 'mobile'
          ? `height: ${getWindowHeight}px; max-width: ${getWindowWidth}px!important; `
          : ''
      "
    >
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({
    enableShrink: true,
    enableHide: false,
    isVisible: true,
    moreMenu: [
      {
        title: "Versión web",
        link: { name: "changeVersion", params: { version: "web" } },
        icon: "mdi-laptop",
      },
      { title: "Salir", link: { name: "mobileLogout" }, icon: "mdi-power" },
    ],
  }),
  watch: {
    getOrientation() {
      if (
        (this.getOrientation === 90 || this.getOrientation === -90) &&
        this.$route.name !== "mobileMap"
      ) {
        this.enableHide = true;
        this.enableShrink = false;
        this.isVisible = false;
      } else {
        this.enableHide = false;
        this.enableShrink = true;
        this.isVisible = true;
      }
    },
  },
  computed: {
    ...mapGetters("screen", [
      "getWindowHeight",
      "getWindowWidth",
      "getOrientation",
    ]),
    isMobile() {
      return this.$isMobile();
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
      window.addEventListener("orientationchange", this.onOrientationChange);
    });
    this.$store.commit("screen/setWindowHeight");
    this.$store.commit("screen/setWindowWidth");
    this.$store.commit("screen/setOrientation");
    this.onLoadOrientation();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("orientationchange", this.onOrientationChange);
  },
  methods: {
    onResize() {
      this.$store.commit("screen/setWindowHeight");
      this.$store.commit("screen/setWindowWidth");
    },
    onOrientationChange() {
      this.$store.commit("screen/setOrientation");
      this.$store.commit("screen/setWindowHeight");
      this.$store.commit("screen/setWindowWidth");
    },
    onLoadOrientation() {
      if (this.getOrientation === 90 || this.getOrientation === -90) {
        this.enableHide = true;
      } else {
        this.enableHide = false;
      }
    },
  },
};
</script>

<style lang="sass">
@import './assets/sass/app.scss'
.app-bar-icon-text
  font-size: 18px
  color: #23c577!important
.app-bar-mobile
  z-index: 100!important
.scroll-container-app-bar
  overflow-y: auto
  overflow-x: hidden
.v-toolbar__content
  justify-content: center
.chevron-toggle
  position: fixed!important
  z-index: 101
  background-color: white
  width: 50px
  cursor: pointer
  -webkit-box-shadow: 0px -2px 15px 2px rgba(186,186,186,0.59)
  box-shadow: 0px -2px 15px 2px rgba(186,186,186,0.59)
.chevron-toggle-down
  bottom: 96px
.chevron-toggle-up
  bottom: 0px
</style>
