import httpClient from './httpClient';

const baseEndPoint = '/get-technology-types';

const getTechnologyTypes = () =>
    httpClient.get(`${baseEndPoint}`)

export default {
    getTechnologyTypes
};
