const labelsEn = {
    listenerType: 'Listener type',
    conditionType: 'Condition type',
    email: 'Email',
    name: 'Name',
    address: 'Address',
    genre: 'Genre',
    phone: 'Phone',
    rut: 'RUT/RUN',
    passport: 'Passport',
    birth: 'Birth date',
    status: 'Status',
    password: 'Password',
    passwordConfirm: 'Confirm password',
    subscriptionDate: 'Subscription date',
    createdAt: 'Created at',
    autogeneratePassword: 'Auto-generate password and send it by mail',
    doNotChange: 'Do not change',
    role: 'Role',
    conditionValue: 'Condition value',
    reference: "Reference",
    description: "Description",
    timeout: "Timeout in seconds"
}

const labelsEs = {
    listenerType: 'Tipo de listener',
    conditionType: 'Tipo de condición',
    email: 'Email',
    name: 'Nombre',
    address: 'Dirección',
    genre: 'Género',
    phone: 'Phone',
    rut: 'RUT/RUN',
    passport: 'Pasaporte',
    birth: 'Fecha de nacimiento',
    status: 'Estado',
    newPassword: 'Nueva clave',
    newPasswordConfirm: 'Confirmar nueva clave',
    password: 'Contraseña',
    passwordConfirm: 'Confirmar contraseña',
    subscriptionDate: 'Fecha de suscripción',
    createdAt: 'Creación',
    autogeneratePassword: 'Auto-generar contraseña y enviarla por email',
    doNotChange: 'No cambiar',
    role: 'Rol',
    conditionValue: 'Valor',
    reference: 'Referencia',
    description: "Descripción",
    timeout: "Timeout en segundos"
}

export default {
    labelsEn,
    labelsEs
}