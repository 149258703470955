import httpClient from './httpClient';

const baseEndPoint = '/user';

const getUser = (user) =>
    httpClient.get(`${baseEndPoint}/${user}`);
const getOrganizationUsers = () =>
    httpClient.get(`/organization-users`);
const getUserInfo = () =>
    httpClient.get('/my-info');
const getUsers = (page, filter) =>
    httpClient.get(`${baseEndPoint}s`, { params: { page, filter } });
const storeUser = (user) =>
    httpClient.post(baseEndPoint, user);
const updateUser = (user) =>
    httpClient.put(`${baseEndPoint}/${user.id}`, user);
const deleteUser = (user) =>
    httpClient.delete(`${baseEndPoint}/${user}`);
const editUserInfo = (user) => httpClient.put(`/edit-user-info`, user);
export default {
    getUser,
    getUserInfo,
    getUsers,
    storeUser,
    updateUser,
    deleteUser,
    editUserInfo,
    getOrganizationUsers,
};
