const state = {
    unitOfMeasurementFromVuex: [
        {
            "id": 1,
            "name": "Celsius",
            "symbol": "°C",
            "variable_id": 1
        },
        {
            "id": 2,
            "name": "Fahrenheit",
            "symbol": "°F",
            "variable_id": 1
        },
        {
            "id": 3,
            "name": "Kelvin",
            "symbol": "K",
            "variable_id": 1
        },
        {
            "id": 4,
            "name": "Atmosphere",
            "symbol": "atm",
            "variable_id": 2
        },
        {
            "id": 5,
            "name": "Millimetre of mercury",
            "symbol": "mmHg",
            "variable_id": 2
        },
        {
            "id": 6,
            "name": "Pascal",
            "symbol": "Pa",
            "variable_id": 2
        },
        {
            "id": 7,
            "name": "Kilopascal",
            "symbol": "kPa",
            "variable_id": 2
        },
        {
            "id": 8,
            "name": "Metres of water column",
            "symbol": "mWC",
            "variable_id": 2
        },
        {
            "id": 9,
            "name": "Pound-force/Square inch",
            "symbol": "psi",
            "variable_id": 2
        },
        {
            "id": 10,
            "name": "Bar",
            "symbol": "bar",
            "variable_id": 2
        },
        {
            "id": 11,
            "name": "Torr",
            "symbol": "Torr",
            "variable_id": 2
        },
        {
            "id": 12,
            "name": "Kilogram per square centimeter",
            "symbol": "Kg/cm2",
            "variable_id": 2
        },
        {
            "id": 13,
            "name": "Percentage",
            "symbol": "%",
            "variable_id": 3
        },
        {
            "id": 14,
            "name": "Percentage",
            "symbol": "%",
            "variable_id": 4
        },
        {
            "id": 15,
            "name": "Centimeters",
            "symbol": "cm",
            "variable_id": 4
        },
        {
            "id": 16,
            "name": "Millimeters",
            "symbol": "mm",
            "variable_id": 4
        },
        {
            "id": 17,
            "name": "Meters",
            "symbol": "m",
            "variable_id": 4
        },
        {
            "id": 18,
            "name": "Volts",
            "symbol": "V",
            "variable_id": 5
        },
        {
            "id": 19,
            "name": "milliVolts",
            "symbol": "mV",
            "variable_id": 5
        },
        {
            "id": 20,
            "name": "microVolts",
            "symbol": "µV",
            "variable_id": 5
        },
        {
            "id": 21,
            "name": "Amperes",
            "symbol": "I",
            "variable_id": 6
        },
        {
            "id": 22,
            "name": "milliAmperes",
            "symbol": "mI",
            "variable_id": 6
        },
        {
            "id": 23,
            "name": "microAmperes",
            "symbol": "µI",
            "variable_id": 6
        },
        {
            "id": 24,
            "name": "Revolutions per minute",
            "symbol": "rpm",
            "variable_id": 7
        },
        {
            "id": 25,
            "name": "Cycles per second",
            "symbol": "cps",
            "variable_id": 7
        },
        {
            "id": 26,
            "name": "Radians per second",
            "symbol": "rad/s",
            "variable_id": 7
        },
        {
            "id": 27,
            "name": "On/Off",
            "symbol": "",
            "variable_id": 8
        },
        {
            "id": 28,
            "name": "Kilometers per hour",
            "symbol": "Km/h",
            "variable_id": 9
        },
        {
            "id": 29,
            "name": "Meters per second",
            "symbol": "m/s",
            "variable_id": 9
        },
        {
            "id": 30,
            "name": "Miles per hour",
            "symbol": "mph",
            "variable_id": 9
        },
        {
            "id": 31,
            "name": "Knots",
            "symbol": "kn",
            "variable_id": 9
        },
        {
            "id": 32,
            "name": "Millimeters",
            "symbol": "mm",
            "variable_id": 10
        },
        {
            "id": 33,
            "name": "Centimeters",
            "symbol": "cm",
            "variable_id": 10
        },
        {
            "id": 34,
            "name": "Meters",
            "symbol": "m",
            "variable_id": 10
        },
        {
            "id": 35,
            "name": "Miles",
            "symbol": "mi",
            "variable_id": 10
        },
        {
            "id": 36,
            "name": "Foot",
            "symbol": "yd",
            "variable_id": 10
        },
        {
            "id": 37,
            "name": "Inch",
            "symbol": "inch",
            "variable_id": 10
        },
        {
            "id": 38,
            "name": "GSM Signal",
            "symbol": "GSM",
            "variable_id": 11
        },
        {
            "id": 39,
            "name": "Percentage",
            "symbol": "%",
            "variable_id": 11
        },
        {
            "id": 40,
            "name": "Beats per minute",
            "symbol": "bpm",
            "variable_id": 12
        },
        {
            "id": 41,
            "name": "Heart Rate Variability",
            "symbol": "ms",
            "variable_id": 13
        },
        {
            "id": 42,
            "name": "Millimeter of mercury",
            "symbol": "mmHg",
            "variable_id": 14
        },
        {
            "id": 43,
            "name": "Percentaje of SpO2",
            "symbol": "SpO2 (%)",
            "variable_id": 15
        },
        {
            "id": 44,
            "name": "Respiration per minute",
            "symbol": "rpm",
            "variable_id": 16
        },
        {
            "id": 45,
            "name": "Hours",
            "symbol": "h",
            "variable_id": 17
        },
        {
            "id": 46,
            "name": "Minutes",
            "symbol": "min",
            "variable_id": 17
        },
        {
            "id": 47,
            "name": "Seconds",
            "symbol": "s",
            "variable_id": 17
        },
        {
            "id": 48,
            "name": "Milliseconds",
            "symbol": "ms",
            "variable_id": 17
        },
        {
            "id": 49,
            "name": "Years",
            "symbol": "YY",
            "variable_id": 17
        },
        {
            "id": 50,
            "name": "Months",
            "symbol": "MM",
            "variable_id": 17
        },
        {
            "id": 51,
            "name": "Days",
            "symbol": "DD",
            "variable_id": 17
        },
        {
            "id": 52,
            "name": "Kilowatts hour",
            "symbol": "kWh",
            "variable_id": 18
        },
        {
            "id": 53,
            "name": "Megawatts hour",
            "symbol": "MWh",
            "variable_id": 18
        },
        {
            "id": 54,
            "name": "Gigawatss hour",
            "symbol": "GWh",
            "variable_id": 18
        },
        {
            "id": 55,
            "name": "Watts",
            "symbol": "W",
            "variable_id": 19
        },
        {
            "id": 56,
            "name": "Kilowatts",
            "symbol": "Kk",
            "variable_id": 19
        },
        {
            "id": 57,
            "name": "Kilogram of CO2",
            "symbol": "Kg",
            "variable_id": 20
        },
        {
            "id": 58,
            "name": "Dollars",
            "symbol": "USD",
            "variable_id": 21
        },
        {
            "id": 59,
            "name": "Chilean Pesos",
            "symbol": "CLP",
            "variable_id": 21
        },
        {
            "id": 60,
            "name": "pH",
            "symbol": "pH",
            "variable_id": 22
        },
        {
            "id": 61,
            "name": "Siemens per meter",
            "symbol": "S/m",
            "variable_id": 23
        },
        {
            "id": 62,
            "name": "Percentage",
            "symbol": "%",
            "variable_id": 24
        },
        {
            "id": 63,
            "name": "Parts per Million",
            "symbol": "ppm",
            "variable_id": 24
        },
        {
            "id": 64,
            "name": "ON/OFF",
            "symbol": "",
            "variable_id": 25
        },
        {
            "id": 65,
            "name": "Adimensional",
            "symbol": "",
            "variable_id": 26
        },
        {
            "id": 66,
            "name": "String",
            "symbol": "",
            "variable_id": 27
        }
    ]
}
const mutations = {}
const getters = {}
const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}