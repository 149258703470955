import httpClient from './httpClient';

const baseEndPoint = '/worker';

const getSlots = (id) =>
    httpClient.get(`${baseEndPoint}/${id}/get-credential-slots`)

const getEvents = (worker, page) =>
    httpClient.get(`${baseEndPoint}/${worker}/proximity-events?page=${page}`)

const getAddress = (id) =>
    httpClient.get(`${baseEndPoint}/${id}/address`)

const createAddress = (worker) =>
    httpClient.post(`${baseEndPoint}/${worker.id}/address`, worker)

const updateAddress = (worker) =>
    httpClient.put(`${baseEndPoint}/${worker.id}/address`, worker)

const getUnusedCredentials = () =>
    httpClient.get('/get-unused-credentials')

const getDepartments = () =>
    httpClient.get('/getDepartments')

const getWorker = (worker) =>
    httpClient.get(`${baseEndPoint}/${worker}`)

const getWorkers = (options) =>
    httpClient.get(`/v1${baseEndPoint}s`, { params: options })

const createWorker = (worker) =>
    httpClient.post(`${baseEndPoint}`, worker)

const editWorker = (worker) =>
    httpClient.put(`${baseEndPoint}`, worker)

const deleteWorker = (id) =>
    httpClient.delete(`${baseEndPoint}/${id}`)

export default {
    getSlots,
    getEvents,
    getAddress,
    createAddress,
    updateAddress,
    getUnusedCredentials,
    getDepartments,
    getWorker,
    getWorkers,
    createWorker,
    editWorker,
    deleteWorker
};