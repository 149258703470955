const deviceDetailEs = {
  identifier: "Identificador",
  alias: "Alias",
  radiusOfCoverage: "Radio de cobertura",
  gps: "Gps",
  hasGps: "Si tiene gps",
  hasNotGps: "No tiene gps",
  worker: "Trabajador",
  createdAt: "Creado en",
};

export default {
  deviceDetailEs,
};
