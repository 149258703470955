const dashboardFormEs = {
    titleFormCreate: 'Crear un nuevo tablero personalizado',
    titleFormEdit: 'Editar el tablero personalizado',
    name: 'Nombre',
    description: 'Descripción',
    customDashboard: 'Tablero personalizado',
    organizationDisplayers: 'Displayers de la organización'
}

export default {
    dashboardFormEs
}