import httpClient from './httpClient';

const baseEndPoint = '/v1/roles';

const getRoles = () => httpClient.get(`${baseEndPoint}`)


export default {
    getRoles
};
