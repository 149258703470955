import httpClient from './httpClient';

const baseEndPoint = '/device';

const getDevices = (options) =>
    httpClient.get(`/v1${baseEndPoint}s`, { params: options })

const getDevice = (device) =>
    httpClient.get(`/get-device-information/${device}`)

const getDevicesWithPosition = () =>
    httpClient.get(`${baseEndPoint}s-with-position`);
const getLastPosition = device =>
    httpClient.get(`${baseEndPoint}/${device}/last-position`);
const deleteDevice = device =>
    httpClient.delete(`${baseEndPoint}/${device}`);
const createDevice = (device) =>
    httpClient.post(`${baseEndPoint}`, device)
const editDevice = (device) =>
    httpClient.put(`${baseEndPoint}`, device)
const getDevicesMeasurementsByIds = (devices, from, to) =>
    httpClient.post('/v1/export/devices-measurements', {
        devices,
        from,
        to,
    })
const getMatrixNodesStatus = (devices) => 
    httpClient.post('v1/devices-with-status', {
        devices,
    })

export default {
    getDevicesWithPosition,
    getLastPosition,
    deleteDevice,
    createDevice,
    getDevices,
    getDevice,
    editDevice,
    getDevicesMeasurementsByIds,
    getMatrixNodesStatus
};
