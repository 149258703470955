import dashboardForm from "./dashboardForm"
import dashboardDetail from "./dashboardDetail"
import dashboardTab from "./dashboardTab"

const dashboardEs = {
    dashboardTableTitle: 'Tableros personalizados',
    dashboardForm: dashboardForm.dashboardFormEs,
    dashboardDetail: dashboardDetail.dashboardDetailEs,
    dashboardTab: dashboardTab.dashboardTabEs
}

export default {
    dashboardEs
}