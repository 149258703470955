import httpClient from './httpClient';

const baseEndPoint = '/v1/zone';

const getZones = (options) => httpClient.get(`${baseEndPoint}s`, { params: options })

const getZonesTable = (page, filter) => httpClient.get(`${baseEndPoint}s-table`, { params: { page, filter } })

const getZone = (zone) => httpClient.get(`${baseEndPoint}/${zone}`)

const editZone = (zone) => httpClient.put(`${baseEndPoint}/${zone.id}`, zone)

const createZone = (zone) => httpClient.post(`${baseEndPoint}`, zone)

const deleteZone = (zone) => httpClient.delete(`${baseEndPoint}/${zone}`)

export default {
    getZone,
    getZones,
    getZonesTable,
    editZone,
    createZone,
    deleteZone
};
