const workerDetailEs = {
    informationTitle: 'Información del trabajador',
    addresTitle: 'Dirección',
    dateOfBirth: 'Fecha de nacimiento',
    weight: 'Peso',
    addresNoInformation: 'No existe información'
}

export default {
    workerDetailEs
}