const titleEs = {
  dashboard: "Tablero",
  departments: "Departamentos",
  departmentCreate: "Crear nuevo departamento",
  department: "Departamento",
  users: "Usuarios de sistema",
  userCreate: "Crear nuevo usuario de sistema",
  userEdit: "Editar usuario de sistema",
  workers: "Trabajadores",
  workerCreate: "Crear nuevo trabajador",
  worker: "Trabajador",
  userProfile: "Perfil de usuario",
  myInfo: "Información de usuario",
  devices: "Dispositivos",
  deviceCreate: "Crear nuevo dispositivo",
  deviceDetails: "Detalles del dispositivo",
  deviceConfiguration: "Configuración del dispositivo",
  deviceEventListeners: "Observadores de eventos del dispositivo",
  deviceEvents: "Eventos del dispositivo",
  deviceMonitoring: "Monitoreo del dispositivo",
  deviceSlots: "Slots del dispositivo",
  deviceStreaming: "Transmisión de eventos en vivo",
  deviceEventListenerCreate:
    "Crear nuevo observador de eventos para el dispositivo",
  deviceEventListenerEdit: "Editar escuchar de eventos para el dispositivo",
  slotCreate: "Crear nuevo slot para dispositivo",
  slotDetails: "Detalles del slot",
  slotConfiguration: "Configuración del slot",
  slotEventListener: "Observadores de evento del slot",
  slotMonitoring: "Monitoreo del slot",
  slotCalibrator: "Calibrador del slot",
  slotStreaming: "Transmisión de datos del slot",
  slotEventListenerCreate: "Crear nuevo observador de evento para el slot",
  slotEventListenerEdit: "Editar observador de evento del slot",
  contacts: "Contactos",
  demo: "Demo",
  notifications: "Notificaciones",
  notificationDetails: "Detalle de la notificación",
  workspace: "Espacio de trabajo",
  dashboards: "Tableros dinámicos",
  dashboardCreate: "Creación de nuevo tablero dinámico",
  geofence: "Geocercas",
  geofenceCreate: "Creación de nueva geocerca",
  templates: "Plantillas",
  createTemplate: "Crear nueva plantilla",
  newDeviceFromTemplate: "Crear nuevo dispositivo a partir de plantilla",
  template: "Detalle de la plantilla",
  matriz: "Matriz de nodos",
};

export default {
  titleEs,
};
