import httpClient from './httpClient';

const baseEndPoint = '/proximity-events';

const getEvents = (page) =>
    httpClient.get(`${baseEndPoint}?page=${page}`)

export default {
    getEvents
};
