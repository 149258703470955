const confirmationsEn = {
    areYouSure: 'Are you sure?',
    delete: 'You are about to delete a record. Are you sure you are taking this irreversible action?',
    deleteSlot1: 'You are about to delete slot',
    deleteSlot2: 'of device',
    deleteSlot3: 'Are you sure you are taking this irreversible action?',
    deleteDevice1: 'You are about to delete device',
    deleteDevice2: 'Are you sure you are taking this irreversible action?'
}

const confirmationsEs = {
    areYouSure: '¿Estás seguro/a?',
    delete: 'Estás a punto de eliminar un registro. ¿Estás seguro/a de realizar esta acción irreversible?',
    deleteSlot1: 'Estás a punto de eliminar el slot',
    deleteSlot2: 'del dispositivo',
    deleteSlot3: '¿Estás seguro/a de realizar esta acción irreversible?',
    deleteDevice1: 'Estás a punto de eliminar el dispositivo',
    deleteDevice2: '¿Estás seguro/a de realizar esta acción irreversible?'
}

export default {
    confirmationsEn,
    confirmationsEs
}