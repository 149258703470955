import Dashboard from '../../services/dashboard.api';

const state = {};

const mutations = {};

const getters = {};

const actions = {
    getDashboards: (_store, options) =>
        Dashboard.getDashboards(options)
            .then(({ data }) => data)
            .catch((err => console.error(err))),
    getDashboardsTable: (_store, { page, filter }) =>
        Dashboard.getDashboardsTable(page, filter)
            .then(({ data }) => data)
            .catch((err => console.error(err))),
    getDashboard: (_store, dashboard) =>
        Dashboard.getDashboard(dashboard)
            .then(({ data }) => data)
            .catch((err) => console.error(err)),
    editDashboard: (_store, dashboard) =>
        Dashboard.editDashboard(dashboard)
            .then(({ data }) => data)
            .catch((err) => console.error(err)),
    editDashboardContent: (_store, dashboard) =>
        Dashboard.editDashboardContent(dashboard)
            .then(({ data }) => data)
            .catch((err) => console.error(err)),
    createDashboard: (_store, dashboard) =>
        Dashboard.createDashboard(dashboard)
            .then(({ data }) => data)
            .catch((err) => console.error(err)),
    deleteDashboard: (_store, dashboard) =>
        Dashboard.deleteDashboard(dashboard)
            .then(({ data }) => data)
            .catch((err) => console.error(err)),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};