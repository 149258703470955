import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

const oceanBlue = {
  primary: colors.blue.base, // primary main
  primarylight: colors.blue.lighten4, // primary light
  primarydark: colors.blue.darken4, // primary dark
  secondary: colors.cyan.base, // secondary main
  secondarylight: colors.cyan.lighten4, // secondary light
  secondarydark: colors.cyan.darken4, // secondary dark
  anchor: colors.blue.base, // link
};

const tgc = {
  primary: "#23c577", // primary main
  primarylight: "#23c577", // primary light
  primarydark: "#23c577", // primary dark
  secondary: "#23c577", // secondary main
  secondarylight: "#23c577", // secondary light
  secondarydark: "#23c577", // secondary dark
  anchor: "#23c577", // link
};

const openWorld = {
  primary: "#00B7D6", // primary main
  primarylight: "#00B7D6", // primary light
  primarydark: "#00B7D6", // primary dark
  secondary: "#00B7D6", // secondary main
  secondarylight: "#00B7D6", // secondary light
  secondarydark: "#00B7D6", // secondary dark
  anchor: "#00B7D6", // link
};

const geox = {
  primary: "#88BD3A", // primary main
  primarylight: "#00B7D6", // primary light
  primarydark: "#00B7D6", // primary dark
  secondary: "#173C42", // secondary main
  secondarylight: "#00B7D6", // secondary light
  secondarydark: "#00B7D6", // secondary dark
  anchor: "#A8BF6A", // link
};

export const theme = {
  primary: process.env.VUE_APP_MAIN_THEME_PRIMARY,
  primarylight: process.env.VUE_APP_MAIN_THEME_PRIMARY_LIGHT,
  primarydark: process.env.VUE_APP_MAIN_THEME_PRIMARY_DARK,
  secondary: process.env.VUE_APP_MAIN_SECONDARY,
  secondarylight: process.env.VUE_APP_MAIN_SECONDARY_LIGHT,
  secondarydark: process.env.VUE_APP_MAIN_SECONDARY_DARK,
  anchor: process.env.VUE_APP_MAIN_ANCHOR,
};

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
  },
  theme: {
    themes: {
      light: {
        ...theme,
      },
    },
  },
});
