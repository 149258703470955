import TechnologyType from '@/services/technologyType.api'

const state = {};

const mutations = {};

const getters = {};

const actions = {
    getTechnologyTypes: () =>
        TechnologyType.getTechnologyTypes()
            .then(({ data }) => data)
            .catch((err) => {
                console.error(err)
            })
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};