const dummydata={
    type:"scatter",
  x:  ["2021-03-23 18:43:28","2021-03-23 18:48:32","2021-03-23 18:53:36","2021-03-23 18:58:40","2021-03-23 19:03:43","2021-03-23 19:08:47","2021-03-23 19:13:51","2021-03-23 19:18:55","2021-03-23 19:23:58","2021-03-23 19:29:02"],
  y:[15.59,15.5,15.4,15.31,15.3,15.18,15.13,15.15,15.15,15.11]

}

const dummyannotations=[
    {
      letter: "A",
      date: "2021-03-23 18:48:32",
      value:15.5,
      title:"Error en montaje",
      description: "Mantencion de polin en terreno",
      type:2//icon
    },
    {
      letter: "B",
      date: "2021-03-23 19:08:47",
      value:15.18,
      title:"Corrida de correa",
      description: "Mantencion de polin en planta",
      type:0
    },
    {
      letter: "C",
      date: "2021-03-23 19:29:02",
      value:15.11,
      title:"Incendio! HELP",
      description: "Mantencion de polin en terreno",
      type:1
    },
  ]

  export {dummydata,dummyannotations}