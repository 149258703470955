import geofenceForm from "./geofenceForm"
import geofenceDetail from "./geofenceDetail"
import geofenceTab from "./geofenceTab"

const geofenceEs = {
    geofenceForm: geofenceForm.geofenceFormEs,
    geofenceDetail: geofenceDetail.geofenceDetailEs,
    geofenceTab: geofenceTab.geofenceTabEs,
}

export default {
    geofenceEs
}