// import the api endpoints
import Listener from '../../services/listener.api';

const state = {};

const mutations = {};

const getters = {};

const actions = {
  getDeviceListenerTypes: () =>
    Listener.getDeviceListenerTypes()
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  getSlotListenerTypes: () =>
    Listener.getSlotListenerTypes()
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  getConditionTypes: () =>
    Listener.getConditionTypes()
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  getListenerReference: (_store, { device, listenerName }) =>
    Listener.getListenerReference(device, listenerName)
      .then(({ data }) => data)
      .catch(err => console.error(err))
  ,
  getDeviceListeners: (_store, { device, options }) =>
    Listener.getDeviceListeners(device, options)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  getDeviceListener: (_store, { device, listener }) =>
    Listener.getDeviceListener(device, listener)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  storeDeviceListener: (_store, { device, listener }) =>
    Listener.storeDeviceListener(device, listener)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  updateDeviceListener: (_store, { device, listener }) =>
    Listener.updateDeviceListener(device, listener)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  deleteDeviceListener: (_store, { device, eventListener }) =>
    Listener.deleteDeviceListener(device, eventListener)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  getSlotListeners: (_store, { device, slot, options }) =>
    Listener.getSlotListeners(device, slot, options)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  getSlotListener: (_store, { device, slot, eventListener }) =>
    Listener.getSlotListener(device, slot, eventListener)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  storeSlotListener: (_store, { device, slot, listener }) =>
    Listener.storeSlotListener(device, slot, listener)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  updateSlotListener: (_store, { device, slot, listener }) =>
    Listener.updateSlotListener(device, slot, listener)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  deleteSlotListener: (_store, { device, slot, eventListener }) =>
    Listener.deleteSlotListener(device, slot, eventListener)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  getItem: (_store, { device, slot }) =>
    Listener.getItem(device, slot)
      .then(({ data }) => { data })
      .catch((err => console.error(err)))
  ,
  createItem: (_store, { device, name, listenerTypeId, isActive }) =>
    Listener.createItem(device, name, listenerTypeId, isActive)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  editItem: (_store, { device, id, name, listenerTypeId, isActive }) =>
    Listener.editItem(device, id, name, listenerTypeId, isActive)
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
  deleteSlot: (_store, { device, slot }) =>
    Listener.deleteSlot(device, slot)
      .then(({ data }) => data)
      .catch((err => console.error(err)))

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
