const templateFormEs = {
  name: "Nombre de plantilla con la cual se identifica",
  description: "Texto descriptivo de lo que contiene la plantilla.",
  withChannels:
    "Usar los mismos canales y event listeners del dispositivo con el cual se crea la plantilla.",
};

export default {
  templateFormEs,
};
