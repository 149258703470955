import departmentForm from "./departmentForm";
import departmentTab from "./departmentTabs";
import departmentDetails from "./departmentDetails";

const departmentEs = {
  departmentTableTitle: "Departamentos",
  departmentForm: departmentForm.departmentFormEs,
  departmentTab: departmentTab.departmentTabEs,
  departmentDetails: departmentDetails.departmentDetailsEs,
};

export default {
  departmentEs,
};
