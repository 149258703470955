import Vue from "vue";
import VueI18n from "vue-i18n";
//Translate files
import sidebar from "@/assets/translate/sidebar";
import users from "@/assets/translate/users";
import eventListeners from "@/assets/translate/eventListeners";
import labels from "@/assets/translate/labels";
import filters from "@/assets/translate/filters";
import validationMessages from "@/assets/translate/validationMessages";
import toast from "@/assets/translate/toast";
import buttons from "@/assets/translate/buttons";
import confirmations from "@/assets/translate/confirmations";
import errorMessages from "@/assets/translate/errorMessages";
// import dashboard from '@/assets/translate/dashboard/dashboard'
import displayers from "@/assets/translate/displayers/displayers";
// import tooltips from "@/assets/translate/tooltips";

//topBar
import title from "@/assets/translate/topBar/title";
import websocket from "@/assets/translate/topBar/websocket";
//dashboard
import dashboard from "@/assets/translate/pages/dashboard/dashboard";
//Users
import user from "@/assets/translate/pages/user/user";
//Departments
import department from "@/assets/translate/pages/department/department";
//Workers
import worker from "@/assets/translate/pages/worker/worker";
//Workspace
import workspace from "@/assets/translate/pages/workspace/workspace";
//Template
import template from "@/assets/translate/pages/template/template";
//Device
import device from "@/assets/translate/pages/device/device";
//Tooltips
import tooltips from "@/assets/translate/tooltips/tooltips";

Vue.use(VueI18n);

const messages = {
  en: {
    users: users.usersEn,
    eventListeners: eventListeners.eventListenersEn,
    labels: labels.labelsEn,
    filters: filters.filtersEn,
    validationMessages: validationMessages.validationMessagesEn,
    toast: toast.toastEn,
    buttons: buttons.buttonsEn,
    confirmations: confirmations.confirmationsEn,
    sidebar: sidebar.sidebarEn,
    errorMessages: errorMessages.errorMessagesEn,
    dashboard: dashboard.dashboardEn,
    displayers: displayers.displayersEn,
    tooltips: tooltips.tooltipsEn,
  },
  es: {
    users: users.usersEs,
    eventListeners: eventListeners.eventListenersEs,
    labels: labels.labelsEs,
    filters: filters.filtersEs,
    validationMessages: validationMessages.validationMessagesEs,
    toast: toast.toastEs,
    buttons: buttons.buttonsEs,
    confirmations: confirmations.confirmationsEs,
    sidebar: sidebar.sidebarEs,
    errorMessages: errorMessages.errorMessagesEs,
    dashboard: dashboard.dashboardViewEs,
    displayers: displayers.displayersEs,
    //Nuevo
    title: title.titleEs,
    websocket: websocket.websocketEs,
    user: user.userEs,
    department: department.departmentEs,
    worker: worker.workerEs,
    workspace: workspace.workspaceEs,
    template: template.templateEs,
    device: device.deviceEs,
    tooltips: tooltips.tooltipsEs,
  },
};

const getNavigatorLanguage = () =>
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.userLanguage ||
      navigator.language ||
      navigator.browserLanguage ||
      "en";
export default new VueI18n({
  locale: "es",
  messages,
  silentTranslationWarn: true,
});
