const deviceMonitoringEs = {
  csvButton: "Descargar CSV",
  dateTimeRange: "Seleccione rango de fechas",
  selectTime: "Seleccionar tiempo",
  selectDate: "Seleccionar fecha",
  close: "Cerrar",
};

export default {
  deviceMonitoringEs,
};
