<template>
  <div
    class="sidebar sidebar-image-ow"
    :data-color="sidebarItemColor"
    :data-image="sidebarBackgroundImage"
    :style="sidebarStyle"
  >
    <div class="logo">
      <router-link to="/" class="simple-text logo-mini">
        <div class="logo-img">
          <img :src="imgLogo" alt="" />
        </div>
      </router-link>

      <router-link to="/" class="simple-text logo-normal">
        {{ title }}
      </router-link>
    </div>
    <div class="sidebar-wrapper">
      <slot name="content"></slot>
      <md-list class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot>
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            :link="link"
          >
          </sidebar-link>
        </slot>
      </md-list>
    </div>
  </div>
</template>
<script>
import SidebarLink from "./SidebarLink.vue";

export default {
  components: {
    SidebarLink,
  },
  props: {
    title: {
      type: String,
      default: process.env.VUE_APP_SIDEBAR_TITLE,
    },
    sidebarBackgroundImage: {
      type: String,
      default: require(`${process.env.VUE_APP_SIDEBAR_IMAGE}`),
    },
    imgLogo: {
      type: String,
      default: require(`${process.env.VUE_APP_SIDEBAR_LOGO}`),
    },
    // add custo theme
    sidebarItemColor: {
      type: String,
      default: "mainTheme",
      validator: (value) => {
        let acceptedValues = [
          "",
          "purple",
          "blue",
          "green",
          "orange",
          "red",
          "ow",
          "oceanBlue",
          "tgc",
          "openWorld",
          "mainTheme"
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.sidebarBackgroundImage})`,
      };
    },
  },
};
</script>
<style>
@media screen and (min-width: 991px) {
  .nav-mobile-menu {
    display: none;
  }
}
.sidebar-image-ow {
  background-position: 65% center !important;
}
</style>
65%