const templateDetailEs = {
  informationTitle: "Información de la plantilla",
  name: "Nombre",
  description: "Descripción",
  slotsTitle: "Slots de la plantilla",
};

export default {
  templateDetailEs,
};
