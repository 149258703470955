import DynamicForm from '../../services/dynamicForm.api'

const state = {};

const mutations = {};

const getters = {};

const actions = {
    getDynamicForm: (_state, dynamicForm) =>
        DynamicForm.getDynamicForm(dynamicForm)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};