const templateFormEs = {
  titleFormCreate: "Crear una nueva plantilla",
  name: "Nombre",
  description: "Descripción",
  withChannels: "Incluir canales y observadores",
  withoutChannels: "No incluir canales y observadores",
};

export default {
  templateFormEs,
};
