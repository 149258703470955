<template>
  <div></div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "TglWebsocket",
  computed: {
    ...mapGetters("auth", ["getAuth"]),
  },
  watch: {
    getAuth: {
      handler(token) {
        if (token) {
          this.$tgcWebsocket.initWsOptions(token);
          this.$tgcWebsocket.openConnection();
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.$tgcWebsocket.closeConnection();
  },
};
</script>

<style>
</style>