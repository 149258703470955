import Worker from '../../services/worker.api'

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getSlots: (_store, id) =>
        Worker.getSlots(id)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    getEvents: (_store, { worker, page }) =>
        Worker.getEvents(worker, page)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    getAddress: (_store, id) =>
        Worker.getAddress(id)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    createAddress: (_store, payload) => Worker.createAddress(payload)
        .then(({ data }) => data)
        .catch((err => console.error(err)))
    ,
    updateAddress: (_store, payload) => Worker.updateAddress(payload)
        .then(({ data }) => data)
        .catch((err => console.error(err)))
    ,
    getUnusedCredentials: () =>
        Worker.getUnusedCredentials()
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    getDepartments: () =>
        Worker.getDepartments()
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    getWorker: (_store, worker) =>
        Worker.getWorker(worker)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    getWorkers: (_store, options) =>
        Worker.getWorkers(options)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    createWorker: (_store, payload) =>
        Worker.createWorker(payload)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    editWorker: (_store, payload) =>
        Worker.editWorker(payload)
            .then(({ data }) => data)
            .catch((err => console.error(err)))
    ,
    deleteWorker: (_store, id) =>
        Worker.deleteWorker(id)
            .then(({ data }) => data)
            .catch((err => console.error(err)))

}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}