import NotificationChannel from '../../services/notificationChannel.api';

const state = {};

const mutations = {};

const getters = {};

const actions = {
  getNotificationChannels: () =>
    NotificationChannel.getNotificationChannels()
      .then(({ data }) => data)
      .catch((err => console.error(err)))
  ,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
