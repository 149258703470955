const labelsEn = {
    title: 'Title',
    symbol: 'Symbol',
    showExportButton: 'Show export button',
    paperBgColor: 'Background panel',
    plotBgColor: 'Background chart',
    icon: 'Icon'
}

const labelsEs = {
    title: 'Título',
    symbol: 'Simbolo',
    showExportButton: 'Mostrar boton de exportar',
    paperBgColor: 'Fondo para el panel',
    plotBgColor: 'Fondo para gráfico',
    icon: 'Icono'

}

export default {
    labelsEn,
    labelsEs
}