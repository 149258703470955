import DashboardLayout from "../pages/Layout/DashboardLayout.vue";
import auth from "../routes/middlewares/auth";
import guest from "../routes/middlewares/guest";

const Contacts = () => import("../pages/Contacts.vue");
const Dashboard = () => import("../pages/Dashboard.vue");
const FakeDashboard = () => import("../pages/FakeDashboard.vue");
const DynamicDashboardForm = () =>
  import("../pages/Dashboard/DynamicDashboardForm");
const DynamicDashboards = () => import("../pages/Dashboard/DynamicDashboards");
const DepartmentForm = () => import("../pages/DepartmentForm.vue");
const DepartmentView = () => import("../pages/DepartmentView.vue");
const Departments = () => import("../pages/Departments.vue");
const Devices = () => import("../pages/Devices/Devices.vue");
const DeviceForm = () => import("../pages/Devices/DeviceForm.vue");
const DeviceProfile = () => import("../pages/Devices/DeviceProfile.vue");
const DeviceDetails = () => import("../pages/Devices/DeviceDetails.vue");
const DeviceEventListeners = () =>
  import("../pages/Devices/DeviceEventListeners.vue");
const DeviceEvents = () => import("../pages/Devices/DeviceEvents.vue");
const DeviceEventListenerForm = () =>
  import("../pages/EventListeners/DeviceEventListenerForm.vue");
const DeviceMonitoring = () => import("../pages/Devices/DeviceMonitoring.vue");
const DeviceSlots = () => import("../pages/Devices/DeviceSlots.vue");
const DeviceStreaming = () => import("../pages/Devices/DeviceStreaming.vue");
const SlotProfile = () => import("../pages/Slots/SlotProfile.vue");
const SlotDetails = () => import("../pages/Slots/SlotDetails.vue");
const SlotConfiguration = () => import("../pages/Slots/SlotConfiguration.vue");
const SlotMonitoring = () => import("../pages/Slots/SlotMonitoring.vue");
const SlotEventListeners = () =>
  import("../pages/Slots/SlotEventListeners.vue");
const SlotEventListenerForm = () =>
  import("../pages/EventListeners/SlotEventListenerForm.vue");
const SlotStreaming = () => import("../pages/Slots/SlotStreaming.vue");
const SlotForm = () => import("../pages/Slots/SlotForm.vue");
const SlotCalibrator = () => import("../pages/Slots/SlotCalibrator.vue");
const Vehicles = () => import("../pages/Vehicles.vue");
const WorkerForm = () => import("../pages/WorkerForm.vue");
const WorkerView = () => import("../pages/WorkerView.vue");
const Workers = () => import("../pages/Workers.vue");
const UserProfile = () => import("../pages/Users/UserProfile.vue");
const Demo = () => import("../pages/Demo");
const NotificationTable = () => import("../pages/NotificationTable.vue");
const NotificationDetails = () => import("../pages/NotificationDetails.vue");
const Users = () => import("../pages/Users/Users");
const MyInfo = () => import("../pages/Users/MyInfo");
const UserForm = () => import("../pages/Users/UserForm");
const Login = () => import("../pages/Login.vue");
const ForgotPassword = () => import("../pages/ForgotPassword.vue");
const ResetPassword = () => import("../pages/ResetPassword.vue");
const EmailSent = () => import("../pages/EmailSent.vue");
const Test = () => import("../pages/Test.vue");
const Test2 = () => import("../pages/Test2.vue");
const TemplatesCrud = () => import("../pages/Templates/TemplatesCrud.vue");
const CreateTemplate = () => import("../pages/Templates/CreateTemplate.vue");
const CreateDeviceFromSelectedTemplate = () =>
  import("../pages/Templates/CreateDeviceFromSelectedTemplate.vue");
const TemplateDetails = () => import("../pages/Templates/TemplateDetails.vue");
const NewDeviceFromTemplate = () =>
  import("@/pages/Templates/NewDeviceFromTemplate.vue");
const Workspace = () => import("../pages/Workspace.vue");
const NotFound = () => import("@/pages/ErrorPage");
const ForbiddenPage = () => import("@/pages/ForbiddenPage");
const GeofenceForm = () => import("@/pages/Geofence/GeofenceForm");
const Geofence = () => import("@/pages/Geofence/Geofence");

//Pucobre
const Matriz = () => import("@/pages/MatrizPage");

//Mobile Components
const MobileLayout = () => import("@/pages/Mobile/MobileLayout");
const MobileMap = () => import("@/pages/Mobile/Maps/MobileMap");
const MobileDevices = () => import("@/pages/Mobile/Devices/DevicesTable");
const MobileDeviceSlots = () =>
  import("@/pages/Mobile/Devices/Slots/SlotsTable");
const MobileDeviceDetails = () =>
  import("@/pages/Mobile/Devices/DeviceDetails");
const MobileDeviceMonitoringLatest = () =>
  import("@/components/Mobile/Devices/Monitoring/Latest");
const MobileDeviceMonitoringHistorical = () =>
  import("@/components/Mobile/Devices/Monitoring/Historical");
const MobileNotificationTable = () =>
  import("@/pages/Mobile/Notifications/NotificationsTable");
const MobileNotificationDetails = () =>
  import("@/pages/Mobile/Notifications/NotificationDetails");

//Bypass
const MobileLogout = () => import("@/pages/Mobile/Logout");
const ChangeVersion = () => import("@/pages/ChangeVersion");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        meta: {
          title: "Dashboard",
          translate: "dashboard",
          middleware: [auth],
        },
        component: Dashboard,
      },
      {
        path: "demo-dashboard",
        name: "fakeDashboard",
        meta: {
          title: "Dashboard",
          middleware: [auth],
        },
        component: FakeDashboard,
      },
      {
        path: "departments",
        name: "departments",
        meta: {
          title: "Departments",
          translate: "departments",
          middleware: [auth],
        },
        component: Departments,
      },
      {
        path: "departments/create",
        name: "departmentCreate",
        meta: {
          title: "Create a department",
          translate: "departmentCreate",
          middleware: [auth],
        },
        component: DepartmentForm,
      },
      {
        path: "department/:department/:tab",
        name: "department",
        meta: {
          title: "Department",
          translate: "department",
          middleware: [auth],
        },
        component: DepartmentView,
      },
      {
        path: "users",
        name: "users",
        meta: {
          title: "users.title",
          translate: "users",
          middleware: [auth],
        },
        component: Users,
      },
      {
        path: "users/create",
        name: "userCreate",
        meta: {
          title: "users.cardTitleCreate",
          translate: "userCreate",
          middleware: [auth],
        },
        component: UserForm,
      },
      {
        path: "users/edit/:user",
        name: "userEdit",
        meta: {
          title: "users.cardTitleEdit",
          translate: "userEdit",
          middleware: [auth],
        },
        component: UserForm,
      },
      {
        path: "workers",
        name: "workers",
        meta: {
          title: "Workers",
          translate: "workers",
          middleware: [auth],
        },
        component: Workers,
      },
      {
        path: "workers/create",
        name: "workerCreate",
        meta: {
          title: "Create a worker",
          translate: "workerCreate",
          middleware: [auth],
        },
        component: WorkerForm,
      },
      {
        path: "workers/:worker/:tab",
        name: "worker",
        meta: {
          title: "Worker",
          translate: "worker",
          middleware: [auth],
        },
        component: WorkerView,
      },
      {
        path: "vehicles",
        name: "vehicles",
        meta: {
          title: "Vehicles",
          middleware: [auth],
        },
        component: Vehicles,
      },
      {
        path: "user/:user",
        name: "userProfile",
        meta: {
          title: "User profile",
          translate: "userProfile",
          middleware: [auth],
        },
        component: UserProfile,
      },
      {
        path: "my-info",
        name: "myInfo",
        meta: {
          title: "User Information",
          translate: "myInfo",
          middleware: [auth],
        },
        component: MyInfo,
      },
      {
        path: "devices",
        name: "devices",
        meta: {
          title: "Devices",
          translate: "devices",
          middleware: [auth],
        },
        component: Devices,
      },
      {
        path: "devices/create",
        name: "deviceCreate",
        meta: {
          title: "Create a device",
          translate: "deviceCreate",
          middleware: [auth],
        },
        component: DeviceForm,
      },
      {
        path: "devices/:device",
        name: "deviceProfile",
        redirect: (to) => ({
          name: "deviceMonitoring",
          params: { device: to.params.device },
        }),
        component: DeviceProfile,
        children: [
          {
            path: "details",
            name: "deviceDetails",
            component: DeviceDetails,
            meta: {
              title: "Details",
              translate: "deviceDetails",
              middleware: [auth],
            },
          },
          {
            path: "configuration",
            name: "deviceConfiguration",
            component: DeviceForm,
            meta: {
              title: "Configuration",
              translate: "deviceConfiguration",
              middleware: [auth],
            },
          },
          {
            path: "event-listeners",
            name: "deviceEventListeners",
            component: DeviceEventListeners,
            meta: {
              title: "Event listeners",
              translate: "deviceEventListeners",
              middleware: [auth],
            },
          },
          {
            path: "events",
            name: "deviceEvents",
            component: DeviceEvents,
            meta: {
              title: "Events",
              translate: "deviceEvents",
              middleware: [auth],
            },
          },
          {
            path: "monitoring",
            name: "deviceMonitoring",
            component: DeviceMonitoring,
            meta: {
              title: "Monitoring",
              translate: "deviceMonitoring",
              middleware: [auth],
            },
          },
          {
            path: "monitoring/:tab",
            name: "deviceMonitoringTab",
            component: DeviceMonitoring,
            meta: {
              title: "Monitoring",
              translate: "deviceMonitoring",
              middleware: [auth],
            },
          },
          {
            path: "slots",
            name: "deviceSlots",
            component: DeviceSlots,
            meta: {
              title: "Slots",
              translate: "deviceSlots",
              middleware: [auth],
            },
          },
          {
            path: "streaming",
            name: "deviceStreaming",
            component: DeviceStreaming,
            meta: {
              title: "Streaming",
              translate: "deviceStreaming",
              middleware: [auth],
            },
          },
        ],
      },
      {
        path: "devices/:device/event-listeners/create",
        name: "deviceEventListenerCreate",
        component: DeviceEventListenerForm,
        meta: {
          title: "Create event listener",
          translate: "deviceEventListenerCreate",
          middleware: [auth],
        },
      },
      {
        path: "devices/:device/event-listeners/:eventListener/edit",
        name: "deviceEventListenerEdit",
        component: DeviceEventListenerForm,
        meta: {
          title: "Edit event listener",
          translate: "deviceEventListenerEdit",
          middleware: [auth],
        },
      },
      {
        path: "devices/:device/slots/create",
        name: "slotCreate",
        meta: {
          title: "Create a slot",
          translate: "slotCreate",
          middleware: [auth],
        },
        component: SlotForm,
      },
      {
        path: "devices/:device/slots/:slot",
        name: "slotProfile",
        redirect: (to) => ({
          name: "slotDetails",
          params: { device: to.params.device, slot: to.params.slot },
        }),
        component: SlotProfile,
        children: [
          {
            path: "details",
            name: "slotDetails",
            component: SlotDetails,
            meta: {
              title: "Details",
              translate: "slotDetails",
              middleware: [auth],
            },
          },
          {
            path: "configuration",
            name: "slotConfiguration",
            component: SlotConfiguration,
            meta: {
              title: "Configuration",
              translate: "slotCOnfiguration",
              middleware: [auth],
            },
          },
          {
            path: "event-listeners",
            name: "slotEventListeners",
            component: SlotEventListeners,
            meta: {
              title: "Event listeners",
              translate: "slotEventListners",
              middleware: [auth],
            },
          },
          {
            path: "monitoring",
            name: "slotMonitoring",
            component: SlotMonitoring,
            meta: {
              title: "Monitoring",
              translate: "slotMonitoring",
              middleware: [auth],
            },
          },
          {
            path: "calibrator",
            name: "slotCalibrator",
            component: SlotCalibrator,
            meta: {
              title: "Calibrator",
              translate: "slotCalibrator",
              middleware: [auth],
            },
          },
          {
            path: "streaming",
            name: "slotStreaming",
            component: SlotStreaming,
            meta: {
              title: "Streaming",
              translate: "slotStreaming",
              middleware: [auth],
            },
          },
        ],
      },
      {
        path: "devices/:device/slots/:slot/event-listeners/create",
        name: "slotEventListenerCreate",
        component: SlotEventListenerForm,
        meta: {
          title: "Create event listener",
          translate: "slotEventListenerCreate",
          middleware: [auth],
        },
      },
      {
        path: "devices/:device/slots/:slot/event-listeners/:eventListener/edit",
        name: "slotEventListenerEdit",
        component: SlotEventListenerForm,
        meta: {
          title: "Edit event listener",
          translate: "slotEventListenerEdit",
          middleware: [auth],
        },
      },
      {
        path: "contacts",
        name: "contacts",
        meta: {
          title: "Contacts",
          translate: "contacts",
          middleware: [auth],
        },
        component: Contacts,
      },
      {
        path: "demo",
        name: "demo",
        meta: {
          title: "Demo",
          translate: "demo",
          middleware: [auth],
        },
        component: Demo,
      },
      {
        path: "notifications",
        name: "notifications",
        meta: {
          title: "Notifications",
          translate: "notifications",
          middleware: [auth],
        },
        component: NotificationTable,
      },
      {
        path: "notification/:notification/details",
        name: "notificationDetails",
        meta: {
          title: "Notification details",
          translate: "notificationDetails",
          middleware: [auth],
        },
        component: NotificationDetails,
      },
      {
        path: "/workspace",
        name: "workspace",
        meta: {
          title: "Workspace",
          translate: "workspace",
          middleware: [auth],
        },
        component: Workspace,
      },
      {
        path: "/workspace/:tab",
        name: "workspaceTab",
        meta: {
          title: "Workspace",
          translate: "workspace",
          middleware: [auth],
        },
        component: Workspace,
      },
      {
        path: "/dashboards/:dashboard/:tab",
        name: "dashboards",
        meta: {
          title: "Dashboards",
          translate: "dashboards",
          middleware: [auth],
        },
        component: DynamicDashboards,
      },
      {
        path: "/dashboard/create",
        name: "dashboardCreate",
        meta: {
          title: "Dashboard Create",
          translate: "dashboardCreate",
          middleware: [auth],
        },
        component: DynamicDashboardForm,
      },
      {
        path: "/geofence/create",
        name: "geofenceCreate",
        meta: {
          title: "Geofence Create",
          translate: "geofenceCreate",
          middleware: [auth],
        },
        component: GeofenceForm,
      },
      {
        path: "/geofence/:geofence/:tab",
        name: "geofence",
        meta: {
          title: "Geofences",
          translate: "geofence",
          middleware: [auth],
        },
        component: Geofence,
      },
      {
        path: "/templates",
        name: "templates",
        meta: {
          title: "Template list",
          translate: "templates",
          middleware: [auth],
        },
        component: TemplatesCrud,
      },
      {
        path: "/templates/:device",
        name: "createTemplate",
        meta: {
          title: "Create a new template",
          translate: "createTemplate",
          middleware: [auth],
        },
        component: CreateTemplate,
      },
      {
        path: "/template/newDevice/:deviceTemplate",
        name: "newDeviceFromTemplate",
        meta: {
          title: "New device from template",
          translate: "newDeviceFromTemplate",
          middleware: [auth],
        },
        component: NewDeviceFromTemplate,
      },
      {
        path: "/template/newDevice",
        name: "createDeviceFromTemplate",
        meta: {
          title: "New device from template",
          translate: "newDeviceFromTemplate",
          middleware: [auth],
        },
        component: CreateDeviceFromSelectedTemplate,
      },
      {
        path: "/template/:template/:tab",
        name: "template",
        meta: {
          title: "Template",
          translate: "template",
          middleware: [auth],
        },
        component: TemplateDetails,
      },
      {
        path: "/matriz",
        name: "matriz",
        meta: {
          title: "Matriz de nodos",
          middleware: [auth],
          translate: "matriz",
        },
        component: Matriz,
      },
      {
        path: "/test",
        name: "test",
        meta: {
          title: "test",
        },
        component: Test,
      },
      {
        path: "/test2",
        name: "test2",
        meta: {
          title: "test2",
        },
        component: Test2,
      },
    ],
  },

  {
    path: "/login",
    name: "login",
    meta: {
      title: "Login",
      middleware: [guest],
    },
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    meta: {
      title: "Forgot Password",
      middleware: [guest],
    },
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    meta: {
      title: "Reset Password",
      middleware: [guest],
    },
    component: ResetPassword,
  },
  {
    path: "/email-sent",
    name: "emailSent",
    meta: {
      title: "Email Sent",
      middleware: [guest],
    },
    component: EmailSent,
  },
  {
    path: "/forbidden",
    name: "forbidden",
    meta: {
      title: "Forbidden",
      middleware: [auth],
    },
    component: ForbiddenPage,
  },
  {
    path: "*",
    name: "not-found",
    meta: {
      title: "404 Page not found",
    },
    component: NotFound,
  },
  {
    path: "/change-version/:version",
    name: "changeVersion",
    meta: {
      title: "Cambiar version",
      middleware: [auth],
    },
    component: ChangeVersion,
  },
  {
    path: "/m",
    name: "mobileLayout",
    redirect: "/m/map",
    component: MobileLayout,
    children: [
      {
        path: "/m/map",
        name: "mobileMap",
        meta: {
          title: "Mapa de dispositivos",
          middleware: [auth],
          type: "mobile",
        },
        component: MobileMap,
      },
      {
        path: "/m/devices",
        name: "mobileDevice",
        meta: {
          title: "Dispositivos",
          subtitle: "Lista de dispositivos",
          middleware: [auth],
          type: "mobile",
        },
        component: MobileDevices,
      },
      {
        path: "/m/devices/:device/details",
        name: "mobileDeviceDetail",
        meta: {
          title: "Dispositivos",
          subtitle: "Detalle de dispositivo",
          middleware: [auth],
          type: "mobile",
        },
        component: MobileDeviceDetails,
      },
      {
        path: "/m/devices/:device/latest",
        name: "mobileDeviceMonitoringLatest",
        meta: {
          title: "Dispositivos",
          subtitle: "Último registro",
          middleware: [auth],
          type: "mobile",
        },
        component: MobileDeviceMonitoringLatest,
      },
      {
        path: "/m/devices/:device/historical",
        name: "mobileDeviceMonitoringHistorical",
        meta: {
          title: "Dispositivos",
          subtitle: "Registros históricos",
          middleware: [auth],
          type: "mobile",
        },
        component: MobileDeviceMonitoringHistorical,
      },
      {
        path: "/m/devices/:device/slots",
        name: "mobileDeviceSlots",
        meta: {
          title: "Dispositivos",
          subtitle: "Slots de dispositivo",
          middleware: [auth],
          type: "mobile",
        },
        component: MobileDeviceSlots,
      },
      {
        path: "/m/notifications",
        name: "MobileNotificationTable",
        meta: {
          title: "Notificaciones",
          subtitle: "Listado de notificaciones",
          middleware: [auth],
          type: "mobile",
        },
        component: MobileNotificationTable,
      },
      {
        path: "/m/notification/:notification/details",
        name: "mobileNotificationDetails",
        meta: {
          title: "Detalles de notificación",
          middleware: [auth],
          type: "mobile",
        },
        component: MobileNotificationDetails,
      },
      {
        path: "/m/logout",
        name: "mobileLogout",
        meta: {
          title: "Logout",
          middleware: [auth],
          type: "mobile",
        },
        component: MobileLogout,
      },
      {
        path: "/m/test",
        name: "mobileTest",
        meta: {
          title: "Test",
          middleware: [guest],
          type: "mobile",
        },
        component: Test,
      },
    ],
  },
];

export default routes;
