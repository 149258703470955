const userFormEs = {
  name: "Nombre del usuario",
  lastName: "Apellido del usuario",
  contactNumber:
    "Teléfono de contacto, llegarán mensajes y notificaciones a este teléfono",
  email:
    "Correo de contacto, llegarán mensajes y notificaciones a esta dirección",
  timeZone: "Zona horaria de preferencia del usuario",
  role: "Define los permisos de uso del usuario en la plataforma",
  rut: "Número identificador único e irrepetible de la persona",
  alias: "Nombre corto que haga referencia al usuario de forma rápida",
  department:
    "Sección dentro de la organización en la que se encuentra el nuevo usuario",
  autogeneratePassword:
    "Permite enviar una contraseña aleatoria al usuario vía correo electrónico",
  password: "Clave de entrada a la plataforma del nuevo usuario",
};

export default {
  userFormEs,
};
