import httpClient from './httpClient';

const baseEndPoint = '/password';

const forgotPassword = (email) => httpClient.post(`${baseEndPoint}/forgot`, email)

const resetPassword = (options) => httpClient.post(`${baseEndPoint}/reset`, options)


export default {
    forgotPassword,
    resetPassword
};
