import httpClient from './httpClient';

const baseEndPoint = '/v1/form';

const getDynamicForm = (dynamicForm) =>
    httpClient.get(`${baseEndPoint}/${dynamicForm}`)


export default {
    getDynamicForm
};
