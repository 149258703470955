const validationMessagesEn = {
    required: 'This field is required',
    password: 'This field must contain at least 6 characters',
    expiration: 'The expiration date cannot be earlier than the issue date',
    issue: 'The issue date cannot be a future date',
    email: 'This is not a valid email',
    rut: 'This field is not valid',
    confirmPassword: 'Passwords do not match',
    integer: 'This field must be an integer',
    passwordConfirm: 'Passwords do not match',
    passwordMissing: 'This field must be checked if a password is not included',
}

const validationMessagesEs = {
    required: 'Este campo es requerido',
    password: 'Este campo debe tener al menos 6 caracteres',
    expiration: 'La fecha de expiración no puede ser anterior a la fecha de emisión',
    issue: 'La fecha de emisión no puede estar en el futuro',
    email: 'Este no es un email válido',
    rut: 'Este campo no es válido',
    confirmPassword: 'Las contraseñas no coinciden',
    integer: 'Este campo debe ser un número entero',
    passwordConfirm: 'Las contraseñas deben coincidir',
    passwordMissing: 'Este campo debe estar marcado si no se incluye una contraseña',
}

export default {
    validationMessagesEn,
    validationMessagesEs
}