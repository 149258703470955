import httpClient from './httpClient';

const baseEndPoint = '/v1';
const subEndpointUser = '/user';
const subEndpointInfo = '/my-info';
const subEndpointWizard = '/wizard';

const getUsers = (options) =>
    httpClient.get(`${baseEndPoint}${subEndpointUser}s`, { params: options });
const getMyInfo = () =>
    httpClient.get(`${baseEndPoint}${subEndpointInfo}`);
const editMyInfo = (info) =>
    httpClient.patch(`${baseEndPoint}${subEndpointInfo}`, info)
const getUser = (user) =>
    httpClient.get(`${baseEndPoint}${subEndpointUser}/${user}`)
const editUser = (user) =>
    httpClient.patch(`${baseEndPoint}${subEndpointUser}/${user.id}`, user)
const changePhone = (phone) =>
    httpClient.put(`${baseEndPoint}${subEndpointInfo}/change-phone`, phone)
const changeEmail = (email) =>
    httpClient.put(`${baseEndPoint}${subEndpointInfo}/change-email`, email)
const generateConfirmationCode = (options) =>
    httpClient.post(`${baseEndPoint}${subEndpointInfo}/generate-confirmation-code`, options)
const wizardStart = (user) =>
    httpClient.post(`${baseEndPoint}${subEndpointWizard}/user/step1`, user)
const wizardStep1 = (user) =>
    httpClient.put(`${baseEndPoint}${subEndpointWizard}/user/${user.id}/step1`, user)
const wizardStep2 = (user) =>
    httpClient.put(`${baseEndPoint}${subEndpointWizard}/user/${user.id}/step2`, user)
const wizardStep3 = (user) =>
    httpClient.put(`${baseEndPoint}${subEndpointWizard}/user/${user.id}/step3`, user)
const wizardStep4 = (user) =>
    httpClient.put(`${baseEndPoint}${subEndpointWizard}/user/${user.id}/step4`, user)
const deactivateUser = (user) =>
    httpClient.put(`${baseEndPoint}${subEndpointUser}/${user.id}/deactivate`)
const activateUser = (user) =>
    httpClient.put(`${baseEndPoint}${subEndpointUser}/${user.id}/activate`)
export default {
    getUsers,
    getMyInfo,
    editMyInfo,
    getUser,
    editUser,
    changePhone,
    changeEmail,
    generateConfirmationCode,
    wizardStart,
    wizardStep1,
    wizardStep2,
    wizardStep3,
    wizardStep4,
    deactivateUser,
    activateUser
};
