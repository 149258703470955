const buttonsEn = {
    save: 'Save',
    cancel: 'Cancel',
    edit: 'Edit',
    create: 'Create',
    close: 'Close',
    sendByEmail: 'Send by email',
    enterManually: 'Manually',
    changePassword: 'Change password',
    agree: 'Agree',
    saveAndCreateAnother: 'Save and create another',
    saveAndGoBack: 'Save and go back',
    saveAndStayHere: 'Save and stay here',
    saveAndGoToDevice: 'Save and go to device',
    delete: 'Delete',
    login: 'Login',
    reference: 'Generate Reference'
}

const buttonsEs = {
    save: 'Guardar',
    cancel: 'Cancelar',
    edit: 'Editar',
    close: 'Cerrar',
    create: 'Crear',
    sendByEmail: 'Enviar por email',
    enterManually: 'Manualmente',
    changePassword: 'Cambiar contraseña',
    agree: 'Aceptar',
    saveAndCreateAnother: 'Guardar y crear otro',
    saveAndGoBack: 'Guardar y volver atrás',
    saveAndStayHere: 'Guardar y permanecer aquí',
    saveAndGoToDevice: 'Guardar e ir al dispositivo',
    delete: 'Eliminar',
    login: 'Ingresar',
    reference: 'Generar Referencia'
}

export default {
    buttonsEn,
    buttonsEs
}