import FileManager from '../../services/fileManager.api';

const state = {
};

const mutations = {};

const getters = {};

const actions = {
    uploadFile: (_store, file) =>
        FileManager.uploadFile(file)
            .then(({ data }) => data)
            .catch((err => console.error(err)))

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};