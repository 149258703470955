const usersEn = {
    title: 'Users',
    cardTitleCreate: 'Create a user',
    cardSubtitleCreate: 'Create a new user',
    cardTitleEdit: 'Edit a user',
    cardSubtitleEdit: 'Changing the password is optional',
}

const usersEs = {
    title: 'Usuarios',
    cardTitleCreate: 'Crear un usuario',
    cardSubtitleCreate: 'Crear un nuevo usuario del sistema',
    cardTitleEdit: 'Editar a un usuario',
    cardSubtitleEdit: 'Cambiar la contraseña o el rol del usuario es opcional',
}

export default {
    usersEn,
    usersEs,
}