const deviceFormEs = {
  titleFormCreate: "Crear un nuevo dispositivo",
  titleFormEdit: "Editar dispositivo",
  identifier: "Identificador",
  alias: "Alias",
  radiusOfCoverage: "Radio de cobertura (m)",
  uplinkInterval: "Periodo de transmisión",
  initialPosition: "Tiene posición inicial",
  notInitialPosition: "No tiene posición inicial",
  icons: "Iconos",
  latitud: "Latitud",
  longitude: "Longitud",
};

export default {
  deviceFormEs,
};
